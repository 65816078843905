import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Select from 'react-select';
import { lang } from '../Helpers/lang';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      quizlist:'',
      domainlist:[],
      activePage: 1,
      selectedCateOption:'',
      selectedCatevalue:'',
      quizIDs:'',
      selectid:'',
      selectedStatus:'',
      clicktype:''
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      this.listloadedData()
   }

   listloadedData(postobject='') {
    var qs = require('qs');
     if(postobject!=''){
      var postobject = postobject
     }else{
      var postobject = {
        activePage: this.state.activePage,
        search_all: this.state.search_all,
        admin_id: localStorage.getItem('admin_id')  
        };
     }
    
     axios.post(apiUrl+"quiz/list",qs.stringify(postobject)).then(res => {
      
        if(res.data.status === 'success'){
            this.setState({  quizlist: res.data.quizlist, totalRecord: res.data.records_count}, function() {
              this.quizlist();
            });
  
        }
      }); 
  }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
     this.listloadedData(postobject)

   }

   handleInputChange(event) {
   const {name, value} = event.target; 
   var postObject = {             
      activePage   : this.state.activePage,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };      
   this.setState({
     [name]: value
   },function(){
    this.listloadedData(postObject)
  });
 }

 handleChange = (selOption) => {       	
    this.setState({selectedCateOption:selOption})
    this.setState({  selectedCatevalue : selOption.value});
 };

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    category : formPayload.selectedCatevalue,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.listloadedData(postObject)

} 



//    deletememebership(id){
//     var qs = require('qs');
//     var postObject = {
//       admin_id : localStorage.getItem("admin_id"),
//       id  :id
//     };

//   axios.post(apiUrl+"quiz/delete",qs.stringify(postObject)).then(res => {
//     if(res.data.status === 'success'){       
//           window.location.reload(); 
//     }
//   }); 
// }

activemembership(){
  let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };

        if(stat == "A"){
          axios.post(apiUrl+"quiz/checkBeforeChange",qs.stringify(postObject)).then(res => {
            if(res.data.status === 'success'){
              this.change_status(postObject);
            }else{
              $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
              setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
            }
          });
        }else{
          this.change_status(postObject);
        }
      
}

change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"quiz/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      this.listloadedData()
      // window.location.reload(false);
    }
  }); 
}

confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activemembership();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}

checkRow = (id) => {
  var quizidArr = [...this.state.quizIDs];
  const index = quizidArr.findIndex(quiz_list => quiz_list === id);
  if(index > -1) {
    quizidArr = [...quizidArr.slice(0, index), ...quizidArr.slice(index + 1)]
  } else {
    quizidArr.push(id);   
  }
  this.setState({quizIDs: quizidArr});
}

delete(type,id){
  this.setState({clicktype : type})
  if(type == "delete"){
    this.setState({quizid : id})
    $('.confirm-action-delete-message').html('Are you sure! you want to delete?');	
    $('.confirm-action-delete').addClass('show');	
  }

  if(type == "feed"){    
    $('.confirm-action-delete-message').html('Are you sure! you want to feed data?');	
    $('.confirm-action-delete').addClass('show');	
  }
  
 }

 deleteConfirm =(confirmstatus,id)=>{
  if(this.state.clicktype == "delete"){
    if(confirmstatus == 'yes'){
      $('.confirm-action-delete').removeClass('show');
      var qs = require('qs');       
      var postObject = { 
        column: 'admin_id',         
        id: id,   
        activePage:this.state.activePage,  
        admin_id:localStorage.getItem("admin_id")
      };
      axios.post(apiUrl+"quiz/delete",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){             
          this.listloadedData()
        }
      });  
    }else{
      $('.confirm-action-delete').removeClass('show');	
    }
  }

  if(this.state.clicktype == "feed"){
    if(confirmstatus == 'yes'){
      var qs = require('qs');
      var postObject = {   
        admin_id: localStorage.getItem('admin_id')
      };
    
      axios.post(apiUrl+"quiz/feedqa",qs.stringify(postObject)).then(res => {    
        if(res.data.status == 'success'){
          this.props.navigate('/aifeed')
        }else{
          $('.confirm-action-delete').removeClass('show');
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          setTimeout(() => {
            $('.success_message').html('');
          }, 3000);
        }
      });
    }else{
      $('.confirm-action-delete').removeClass('show');	
    }
  }
}


quizlist() {
  let catImg = '';
  let preview = '';
    var quizlist = this.state.quizlist;
    if (quizlist != "undefined" && quizlist != null) {
    
      if (quizlist.length > 0) {

        if (Object.keys(quizlist).length > 0) {
          const helperlistDetails = quizlist.map(
            (quizlist, Index) => {
              let sno = Index+1;
              

               if(quizlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var answer = quizlist.quiz_answer;
             
              return (
                <tr key={quizlist.id}>
                <td>{sno}</td>
      					<td>{quizlist.quiz_id}</td>
      					<td>{quizlist.quiz_question}</td>
      					<td>{quizlist.quiz_mode}</td>
      					<td>{quizlist.catenames}</td>
                <td width="30px">{status}</td>
                <td className="actiongroup" >
                  
                    <Link to={{ pathname: '/quiz-view/'+quizlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/quiz-edit/'+quizlist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, quizlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, quizlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      
                    <Link onClick={this.delete.bind(this,'delete',quizlist.id)}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                        
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }

  

  export = () => {
    // console.log('aifeedidArr', this.state.quizIDs);
    var quizIDs = this.state.quizIDs;
    if(quizIDs.length > 0){
      var qs = require('qs');
  
      var postObject = {   
          ids: quizIDs   
      };
      // console.log(postObject, 'postObject'); return;
      axios.post(apiUrl+"quiz/export",qs.stringify(postObject)).then(res => {
        // console.log(res, 'ai_res');
        if(res.data.status !== ''){
            $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
                  
            if(res.data.status == 'success'){
              var url = res.data.url;
              var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
              window.open(cleanurl, '_blank', 'noreferrer');
              window.location.reload();
            }
  
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      }); 
    }else{
      alert('Please select atleast one');
    }
  }

  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
    <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the quiz?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="quiz" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Question Management</h3>:<h3>{this.state.totalRecord} Question Management</h3>}
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div class="input_search">
                    <div className="search-inner search_input">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     
                    <div className="erroremail"></div>
                    </div>
                    {/* <div className="search-inner search_select">
                    <Select name="category" options={lang.common.quizcategories_option} 
                          // onChange={this.handleInputChange}
                            onChange={this.handleChange}
                            />
                    </div> */}
                    <div class="search-inner search_btn">
                    <button className="" type="submit">Submit</button>

                    </div>
                    </div>
                 
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" onClick={this.delete.bind(this,'feed')}>Feed to AI</a> &nbsp;|&nbsp;
                    <a className="" href='/quiz-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/quiz-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a> { /*&nbsp;|&nbsp;
                    <a className="" onClick={this.export}>Export <i className="export-icon icon" aria-hidden="true"></i></a>
    <a class="exportcsv_url" href=""></a> */ }
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
                  {/* <th width="10px">S.No</th> */}
			            <th>Question ID</th>
			            <th>Question</th>
			            <th>Mode</th>
			            <th>Category</th>
                  <th width="30px">Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.quizlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>

        <div className="confirm-action-delete">
          <p className="confirm-action-delete-message"></p>
          
          
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.quizid)} className="btn">Yes</a>
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
          <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
        </div>
      
    </div>
    );
  }
}
export default (withRouter(List));
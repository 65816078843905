import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWstudentlist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const session_id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      session_id: session_id,
      sessionlistview:'',
      attendedsessionlist:''
    };    
  }
   
  componentDidMount() {
    document.title = PageTitle('Session View');

    var sess_id = this.state.session_id;
    axios.get(apiUrl+"sessionmanagement/listdetail?id="+sess_id).then(res => {
      if(res.data.status == 'success'){
        const sessionlistview = res.data.sessionlist;
				this.setState({sessionlistview: sessionlistview});
      }      
    }); 

    var qs = require('qs');
    var postObject = { session_id : sess_id };
    axios.post(apiUrl+"sessionmanagement/attended_session",qs.stringify(postObject)).then(res => {					
      if(res.data.status === "success"){
        const attendedsessionlist = res.data.sesslist;
        this.setState({ attendedsessionlist: attendedsessionlist });
        this.setState({ attendedsessiondata: res.data });
        console.log(typeof this.state.attendedsessionlist, 'atrrtrtr')
      }
    });
  }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }

   quizlist(Index){
    console.log(Index, 'Indes')
    var attendedsessionlist = this.state.attendedsessionlist;    
    var quizdetail = attendedsessionlist[Index].quizdetail;
    console.log(quizdetail, 'quizdetail')
    const quizdetails = quizdetail.map(
      (quizdetail, Index) => {

        var quiz_question = ((quizdetail.quiz_question == null)||(quizdetail.quiz_question == '')) ?  quizdetail.quiz_question = 'N/A' : quizdetail.quiz_question;
        var quiz_answer = ((quizdetail.quiz_answer == null)||(quizdetail.quiz_answer == '')) ?  quizdetail.quiz_answer = 'N/A' : quizdetail.quiz_answer;
        return (
          <div>
          <div className="view-details">
            <div className="view-details-one">
                <p><b>Question<span>:</span> </b></p>
            </div>
            <div className="view-details-two">
                <p>{quiz_question}</p>
            </div>
          </div>
          <div className="view-details">
            <div className="view-details-one">
                <p><b>Answer<span>:</span> </b></p>
            </div>
            <div className="view-details-two">
                <p>{quiz_answer}</p>
            </div>
          </div>
          </div>          
        );
      });
      return quizdetails;
   }

   sessionlist() {

    var attendedsessionlist = this.state.attendedsessionlist;
    var attendedsessiondata = this.state.attendedsessiondata;
    if (attendedsessionlist != "undefined" && attendedsessionlist != null) {
      if (attendedsessiondata.status == "success") {
        if (Object.keys(attendedsessionlist).length > 0) {
          console.log(attendedsessionlist, 'attttd')
          const attendedsessionlistDetails = attendedsessionlist.map(
            (attendedsessionlist, Index) => {
              let sno = Index+1;
              if(attendedsessionlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var studentname = ((attendedsessionlist.studentname == null)||(attendedsessionlist.studentname == '')) ?  attendedsessionlist.studentname = 'N/A' : attendedsessionlist.studentname;
              
              return (
                <div>
                  <div className="form-row">
                    <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Student Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{studentname}</p>
                        </div>
                      </div>
                      {this.quizlist(Index)}
                    </div>
                  </div>
                </div>
              );
            }
          );
          return attendedsessionlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
   
  render() {
    

    // var mobileno = ((this.state.sessionlistview.mobile === null)||((this.state.sessionlistview.mobile === ''))) ?  this.state.sessionlistview.mobile = 'N/A' : this.state.sessionlistview.mobile;
    var attendedsessionlist = this.state.attendedsessionlist;
    console.log(attendedsessionlist, '12344')
    if(this.state.sessionlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="sessionmanagement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/sessionmanagement'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Session Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Session Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.sessionlistview.exam_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.sessionlistview.type}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Tutor<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.sessionlistview.tutorname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.sessionlistview.catenames}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Duration (in mins)<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.sessionlistview.duration}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Scheduled_on<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{moment(this.state.sessionlistview.scheduled_on).format("MM/DD/YYYY hh:mm a")}</p>
                          </div>
                          
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{university}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Grade<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{grade}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.sessionlistview.domain_name}</p>
                          </div>
                          
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                        



                    </div>
                </div>

                {(attendedsessionlist).length > 0 && <div className="car-listing-row">
                  <div className="title">
                    <h3>Attended Sessions</h3>
                  </div>
                  {this.sessionlist()}
                </div>              
                }
                  
               
              </div>  

                        
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

import React, { Component } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MyEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorContent: '', // Initialize the editor content in the state
      initialData: '', // Initialize the editor content in the state
    };

    this.editor = null;
  }
  componentDidMount() {
		ClassicEditor
		  .create(document.querySelector(".editor"))
		 
      .then(editor => {
        
        this.editor = editor;
        // Set the initial content from state
      
        
        // if (this.props.initialData) {
        //   this.editor.setData(this.props.initialData);
        // }
        // Listen for changes in the CKEditor content
        this.editor.model.document.on('change:data', (e) => {
          var data =  this.editor.getData();
         
          this.props.onEditorChange(data)
          // this.handleEditorChange();
        });
      })
		  .catch(error => {
			console.error('There was a problem initializing the editor.', error);
		  });
	  }
    

	  componentWillUnmount() {
		if (this.editor) {
		  // If the component is unmounted, you can destroy the CKEditor instance to clean up resources.
		  this.editor.destroy().catch(error => {
			console.error('Error while destroying CKEditor instance:', error);
		  });
		}
	  }
	  
    componentWillReceiveProps(NextProps){
      
     // if (NextProps.initialData!='' && this.props.initialData != NextProps.initialData) {
		  if (this.props.initialData == '') {
        console.log('ck')
		console.log(this.props.initialData,"initialData")
      console.log(NextProps.initialData,"NextProps initialData")
       if (this.editor) {
          this.editor.setData(NextProps.initialData);
        }
      }
    }
    // componentDidUpdate(prevProps) {
    //   console.log(prevProps,"initialData")
    //   if (this.state.initialData !== this.props.initialData) {
    //     if (this.editor) {
    //       this.editor.setData(this.props.initialData || "");
    //     }
    //   }
    // }
  
  render() {
    return (
      <div>
       
        <div className="editor"></div>
      </div>
    );
  }
}

export default MyEditor;

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          studentlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
         
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id'),
      blocked: 1
      };
   }
  
 axios.post(apiUrl+"student/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  studentlist: res.data, totalRecord: res.data.records_count}, function() {
         this.studentlist();
       });
   }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id'),
      blocked: 1  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id'),
    blocked: 1        
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id'),
          blocked: 1          
        };
        this.listloadedData(postObject)
      
  } 


studentlist() {

    var studentlist = this.state.studentlist;
    if (studentlist != "undefined" && studentlist != null) {
      if (studentlist.status == "success") {
        if (Object.keys(studentlist).length > 0) {
          const studentlistDetails = studentlist.studentlist.map(
            (studentlist, Index) => {
              let sno = Index+1;
              if(studentlist.status == 'A'){
                var status = 'active';
              }else if(studentlist.status == 'B'){
                var status = 'blocked';
              }else{
                  status = 'inactive';
              }

              var mobile = ((studentlist.mobile == null)||(studentlist.mobile == '')) ?  studentlist.mobile = 'N/A' : studentlist.mobile;
              
              return (
                <tr key={studentlist.id}>
                  <td>{sno}</td>
                  <td>{studentlist.firstname}</td>                  
                  <td>{studentlist.email}</td>                  
				          <td>{mobile}</td>
                  <td>{studentlist.username}</td>
                  <td>{studentlist.orgpwd}</td>
                  <td>{lang.Capitalize(status)}</td>                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/blockedstudent-edit/'+studentlist.id}} className="" title="Duplicate">  <i className="ft-edit" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return studentlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
    <Header />
    <MenuSidebar currentpage="blockedstudent" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Blocked Students</h3>:<h3>{this.state.totalRecord} Blocked Student</h3>}
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 {/*<a className="" href='/student-add'>Add </a> */} {/*&nbsp;|&nbsp;
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
				        <th>Contact</th>
			          <th>Username</th>
			          <th>Password</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.studentlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWstudentlist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const class_id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      class_id: class_id,
      classlistview:'',
      selectedStudentOption: [],
			selectedStudentvalue:'',
    };    
  }

  handleChange = (r,selOption) => {
       
		 if(r == 'student'){			
			let selStudent = [];
			let selStudentValue = [];
			selOption.map(item => {
				selStudentValue.push(item.value);
				selStudent.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedStudentOption:selStudent})
			this.setState({  selectedStudentvalue : selStudentValue});
		}
 	};
   
  componentDidMount() {
    document.title = PageTitle('Class View');

    var class_id = this.state.class_id;
    axios.get(apiUrl+"classes/listdetail?id="+class_id).then(res => {
      if(res.data.status == 'success'){
        const classlistview = res.data.classlist;
				this.setState({classlistview: classlistview});

        if(res.data.assignedstudentlist){
					this.setState({selectedStudentOption:res.data.assignedstudentlist});
				}
      }      
    }); 

  }

  studentslist = (id) => {
    var qs = require('qs');
    var postobject = {id: id};
    
    let studentlists = [];
    axios.post(apiUrl+"student/studentslist",qs.stringify(postobject)).then(res => {        
        if(res.data.status === "success"){
            let i=0;
            let studentlist = res.data.studentlist;           
            
            for(i; i<studentlist.length; i++){
                let lastname = "";
                if(studentlist[i]['lastname'] != "" && studentlist[i]['lastname'] != null){
                    lastname = studentlist[i]['lastname'];
                }
                let sname = studentlist[i]['firstname'] + ' ' + lastname;
                studentlists[i] = { value: studentlist[i]['id'], label: sname };
            }
            
        }       
    });
    return studentlists;
}

    handleFormSubmit = () => {
        if(this.validateForm()){
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;            
          var student = [];
          
          if(formPayload.selectedStudentvalue !== '' && formPayload.selectedStudentvalue!== 0){
            student = formPayload.selectedStudentvalue;
          }

          var postObject = {
            admin_id : localStorage.getItem("admin_id"),
            id       : formPayload.class_id,
            institute       : this.state.classlistview.institute,
            student		:student
          };
          // console.log(postObject, 'POB'); return;
          axios.post(apiUrl+"classes/assign_students",qs.stringify(postObject)).then(res => {
            
            if(res.data.status === "success"){
              this.setState({ Loading: false });
              
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


                  setTimeout(
                  function() {
                    $('.success_message').html('');
                    //this.props.navigate('/sessionmanagemet');
                  }
                  .bind(this),
                  3000
                  );
            } else {
              this.setState({ Loading: false });
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
                setTimeout(
                function() {
                  $('.success_message').html('');
                }
                .bind(this),
                3000
                );
            }
          });
        }
    }

    validateForm() {
      const {selectedStudentOption} = this.state;
          
      let errors = 0;
     
      if(selectedStudentOption.length === 0){
        errors++;
        $('.errorstudent').html('<span class="errorspan">Please select atleast one student</span>');
      }else{
        
        $('.errorstudent').html('');
      }
    
      if(errors>0) {
      setTimeout(function () {
      scrollToTopValidate();
      }, 100);
      return false;
      }
      else {
      return true;
      }
      }

   
  render() {
    
    const {selectedStudentOption} = this.state;

    if(this.state.classlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }

    // var class_for = ((this.state.classlistview.class_for == null)||(this.state.classlistview.class_for == '')) ?  this.state.classlistview.class_for = 'N/A' : this.state.classlistview.class_for;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="class" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="success_message"></div>
               <div>
			        	<a className="add" href='/class'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Class Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Class Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.classlistview.name}</p>
                          </div>
                        </div>

                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              {/* <p>{class_for}</p> */}
                              <p>{this.state.classlistview.class_for}</p>
                          </div>
                        </div>

                        {(this.state.classlistview.class_for == "institute") &&
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Institute<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.classlistview.ins_name}</p>
                          </div>
                        </div>
                        }


                        <div className="title">
                          <h3>Assign Students</h3>
                        </div>
                        <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
                        <div className="form-left">					
                        <div className="form-group">					
                          {/* <label>Student<span class="required" style={{ color: "red" }} > * </span> :</label> */}
                          <Select 
                          value={selectedStudentOption?selectedStudentOption:""}
                                        options={this.studentslist(this.state.classlistview.institute)} 
                                        onChange={this.handleChange.bind(this, 'student')}
                          isSearchable={true}
                            isMulti
                                        />
                          <div className='errorstudent'></div>
                      </div> 

                      <div className="btn-group export">	
                        <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                                      <span className="load-data"></span> 
                                                      }Submit
                                </button>
                    </div>
                      </div> 
                      </form>

                      
                        



                    </div>
                </div>

                  
               
              </div>  

                        
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

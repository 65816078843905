import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { apiUrl,mediaUrl ,mediaUrlImage} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import MyEditor from './MyEditor';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		super(props);	
	    // const productId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		const productId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
            productId: productId,
			product_name:'',
			price:'',
			// quantity:'',
			description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image:'',
			image_name: '',
			image_preview: '',
			rating:'',
			reviews:''
		};
		var qs = require('qs');
		var pid = this.state.productId;

		axios.get(apiUrl+"product/listdetail?id="+pid).then(res => {
			
			this.setState({  image_name:res.data.productlist.image,product_name:res.data.productlist.name,price:res.data.productlist.price,
				description:res.data.productlist.description,
				quantity:res.data.productlist.quantity,
				author_name:res.data.productlist.author,
				saleprice:res.data.productlist.saleprice,
				rating:res.data.productlist.rating,
				reviews:res.data.productlist.review
			});
			
			if(res.data.productlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}

		}); 
		
		


		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.onEditorChange = this.onEditorChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }



    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	
   handleInputChange(event) {
		const {name, value} = event.target;      
		this.setState({
          [name]: value
        });
	
		if(name ==='no_of_visite'){
			if(value<1){
				this.setState({no_of_visite:''});
			}
	   	}     	
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		
		};
		reader.readAsDataURL(file);
	}
	
	imageRemove = (productId) => {

		var postObject = {
			product_id:productId,
		};
		var qs = require('qs');

		axios.post(apiUrl+"product/deleteProductimage",qs.stringify(postObject) ).then(res => {
			if(res.data.status === "success"){	
				this.setState({image_name:res.data.productlist.image,product_name:res.data.productlist.name,price:res.data.productlist.price,
					description:res.data.productlist.description,
					quantity:res.data.productlist.quantity,
					author_name:res.data.productlist.author,
					rating:res.data.productlist.rating,
					reviews:res.data.productlist.review,
					saleprice:res.data.productlist.saleprice});
				if(res.data.productlist.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}
				
	
			}
		});
	}

	
	componentDidMount() {
		document.title = 'product Edit';
	
		
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}


				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					product_name : formPayload.product_name,
					price: formPayload.price,					
					description: formPayload.description,
					status:  status,
					id: formPayload.productId,
					image: formPayload.image,
					author_name: formPayload.author_name,
					quantity: formPayload.quantity,
					saleprice: formPayload.saleprice,
					review:formPayload.reviews,
					rating:formPayload.rating,
				};
				
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"product/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/product')
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {product_name,
			price,author_name,quantity,description} = this.state;
	
			let errors = {};
			  let formIsValid = true;
	
			if (!product_name) {
				formIsValid = false;
				$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(product_name){
				$('.errorproduct_name').html('');
			}
			if (!author_name) {
				formIsValid = false;
				$('.errorauthor_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(author_name){
				$('.errorauthor_name').html('');
			}
			
			if (!price) {
				formIsValid = false;
				$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
			}else if(price){
				$('.errorprice').html('');
			}

			if (!quantity) {
				formIsValid = false;
				$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
			}else if(quantity){
				$('.errorquantity').html('');
			}
			if(!description){
				formIsValid = false;
				$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
			}else{
				
				$('.errordescription').html('');
			}
	

		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	onEditorChange( evt ) {
		var callfunc = this;
		setTimeout(function () {
		 this.setState( {
			 description: evt
		 } );
		 }.bind(this),1000);	 
	}

	
  render() {
	const navigate = this.props.navigate;
	const {selectedOption } = this.state;
	var current = this;

		
	let loaderimg = '';
	let preview = '';

	  const imagepre = mediaUrlImage+'/'+this.state.image_name;
	
	  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
		  loaderimg = mediaUrlImage + '/'+this.state.image_name;
		 
		  preview = <img className="img_class" src={loaderimg} alt="" />;
		
	  }else{
		
		  preview = <img className="img_class" src={imagepre} alt="" />;
	  }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Product" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div> 
			<div className="title">
				<h4>Product Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
					<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name}/>
					<div className="errorproduct_name"></div>
					</div>
				
					<div className="form-group">
					<label>Author Name<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="text" name="author_name" onChange={this.handleInputChange} className="form-control" value={this.state.author_name}/>
					<div className="errorauthor_name"></div>
				</div>
				<div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div> 
				<div className="form-group">
					<label>Rating  </label>
				    <input type="number" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating}/>
					<div className="errorrating"></div>
				</div> 
				<div className="form-group">					
					<label>Review:</label>
					<input type="number" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews}/>
					<div className="errorreview"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> </label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div>
				
				
			
				
			
			</div>
                  
			<div className="form-right">
				
			<div className="form-group pricediv">
					<label>Price($)<span class="required " style={{ color: "red" }} > * </span>  </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>
				<div className="form-group pricediv">
					<label>Sale Price($) </label>
				    <input type="number" name="saleprice" onChange={this.handleInputChange} className="form-control" value={this.state.saleprice}/>
					<div className="errorsaleprice"></div>
				</div>
				<div className="form-group">					
					<label>Product Description<span class="required " style={{ color: "red" }} > * </span></label>
					<MyEditor id="description" initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
					
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
					<div className="errordescription"></div>
				</div>
				
			   <div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
					<div className="form-group">
						{preview}
						{(this.state.image_name !== null && this.state.image_name !== '') && (
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						)}
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						</div>
					)} 
				</div> 
			
				 
				

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}
export default (withRouter(Edit));
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
	    const id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			id: id,
			name:'',			
			institute:'',			
			class_for:'',			
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedInstituteOption:"",
			selectedInstituteValue:0,
		};

		var qs = require('qs');
		axios.get(apiUrl+"classes/listdetail?id="+id).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.classlist;
				
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}

				this.setState({name:formdata.name});
				this.setState({class_for:formdata.class_for});

				var postObject = {
					id: formdata.institute,			
				};

				axios.post(apiUrl+"classes/institutedetail",qs.stringify(postObject)).then(res => {
					if(res.data.status == "success"){					
						let institutedetail = res.data.institutedetail;
						this.setState({selectedInstituteOption:{value: institutedetail['id'], label: institutedetail['institute']}});					
					}
				});
				
			}
			
		}); 
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'institute'){
			this.setState({selectedInstituteOption:selOption})
			this.setState({  selectedInstituteValue : selOption.value});
		}
 	};

	componentDidMount() {
		document.title = PageTitle('Class Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status, institute = '';

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedInstituteValue === '' && formPayload.selectedInstituteValue!== 0){
					institute =formPayload.selectedInstituteOption.value;
				}else{
					institute = formPayload.selectedInstituteValue;
				}

				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					id       : formPayload.id,
					name     : formPayload.name,					
					class_for     : formPayload.class_for,					
					institute     	: institute,	
					status       :  status,
				};
				axios.post(apiUrl+"classes/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.classlist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/sessionmanagemet');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {name,selectedInstituteOption,class_for} = this.state;
      	// let formIsValid = true;
		  const nameRegex = /^[A-Za-z\s]+$/;

		let errors = 0;
		if (!name) {
			
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			
			$('.errorname').html('');
		}

		if(class_for == "institute"){
			if (!selectedInstituteOption) {			
				errors++;
				$('.errorinstitute').html('<span class="errorspan">Please select any institute</span>');
			}else{
				
				$('.errorinstitute').html('');
			}
		}
	
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }
	
  render() {

  	const {selectedOption,selectedInstituteOption} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="class" />  	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/class'>Back</a> 
			</div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>
				<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange.bind(this, 'status')}
								/>
				</div> 
				
			</div>
			<div className="form-right">

				<div className="form-group">					
					<label>Type<span class="required" style={{ color: "red" }} >*</span></label> 
					<div>
					<span><input onChange={this.handleInputChange} type="radio" name="class_for" checked={this.state.class_for=='Institute'?'checked':''} value="Institute" /> Institute</span> &nbsp;
					<span><input onChange={this.handleInputChange} type="radio" name="class_for" checked={this.state.class_for=='Other'?'checked':''} value="Other" /> Other</span>
					</div>					
				</div>

				{this.state.class_for == "Institute" &&
				<div className="form-group">					
					<label>Institute<span class="required" style={{ color: "red" }} >*</span></label>
						<Select 
						value={selectedInstituteOption?selectedInstituteOption:""}
							options={lang.common.institute_option} 
							onChange={this.handleChange.bind(this, 'institute')}
							/>
							<div className='errorinstitute'></div>
				</div> 
  				}

					
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);
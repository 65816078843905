import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const cateid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      cateid: cateid,
      quizlistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"quiz/listdetail?id="+cateid).then(res => {
        if(res.data.status == 'success'){
          const formdata = res.data.quizlist;
          this.setState({quizlistview:res.data.quizlist});						
          this.setState({catenames:res.data.catenames});						
        }			
      }); 
    }

   
   componentDidMount() {
     document.title = PageTitle('Question Management View');
   }
   
  render() {   

    var parentcate_name = ((this.state.quizlistview.parentcate_name === null)||((this.state.quizlistview.parentcate_name === ''))) ?  this.state.quizlistview.parentcate_name = 'N/A' : this.state.quizlistview.parentcate_name;
    var answer1 = ((this.state.quizlistview.quiz_answer === null)||((this.state.quizlistview.quiz_answer === ''))) ?  this.state.quizlistview.quiz_answer = 'N/A' : this.state.quizlistview.quiz_answer;
    var answer2 = ((this.state.quizlistview.quiz_answer2 === null)||((this.state.quizlistview.quiz_answer2 === ''))) ?  this.state.quizlistview.quiz_answer2 = 'N/A' : this.state.quizlistview.quiz_answer2;
    var answer3 = ((this.state.quizlistview.quiz_answer3 === null)||((this.state.quizlistview.quiz_answer3 === ''))) ?  this.state.quizlistview.quiz_answer3 = 'N/A' : this.state.quizlistview.quiz_answer3;
    var answer4 = ((this.state.quizlistview.quiz_answer4 === null)||((this.state.quizlistview.quiz_answer4 === ''))) ?  this.state.quizlistview.quiz_answer4 = 'N/A' : this.state.quizlistview.quiz_answer4;
    var answer5 = ((this.state.quizlistview.quiz_answer5 === null)||((this.state.quizlistview.quiz_answer5 === ''))) ?  this.state.quizlistview.quiz_answer5 = 'N/A' : this.state.quizlistview.quiz_answer5;

    var correct_answer = this.state.quizlistview.correct_answer;
    
    if(this.state.quizlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="quiz" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/quiz'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Question Management Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Question<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.quizlistview.quiz_question}</p>
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Answer Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.quizlistview.quiz_answer_desc}</p>
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Option A<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{answer1} {( correct_answer == "1" ? "(Correct Answer)" : "")}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Option B<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{answer2} {( correct_answer == "2" ? "(Correct Answer)" : "")}</p>
                          </div>
                        </div>


                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Option C<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{answer3} {( correct_answer == "3" ? "(Correct Answer)" : "")}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Option D<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{answer4} {( correct_answer == "4" ? "(Correct Answer)" : "")}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Option E<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{answer5} {( correct_answer == "5" ? "(Correct Answer)" : "")}</p>
                          </div>
                        </div>
                                                <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.catenames}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>
                    </div>
                </div>               
              </div>  
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import {
  Table
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          sessionlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',         
      };    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"sessionmanagement/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  sessionlist: res.data, totalRecord: res.data.records_count}, function() {
         this.sessionlist();
       });
   }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });    

}

 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

activatesessionmanagement(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          session_id  :this.state.selectid,
		      status: stat
        };
        axios.post(apiUrl+"sessionmanagement/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}


confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activatesessionmanagement();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"sessionmanagement/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}

delete(id){
  this.setState({selectid : id})
  $('.confirm-action-delete-message').html('Are you sure! you want to delete?');	
  $('.confirm-action-delete').addClass('show');	
 }

 deleteConfirm =(confirmstatus,id)=>{
  if(confirmstatus == 'yes'){
    $('.confirm-action-delete').removeClass('show');
    var qs = require('qs');       
    var postObject = { 
      column: 'admin_id',         
      id: id,   
      activePage:this.state.activePage,  
      admin_id:localStorage.getItem("admin_id")
    };
    axios.post(apiUrl+"sessionmanagement/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){             
        this.listloadedData()
      }
    });  
  }else{
    $('.confirm-action-delete').removeClass('show');	
  }
}
  sessionlist() {

    var sessionlist = this.state.sessionlist;
    if (sessionlist != "undefined" && sessionlist != null) {
      if (sessionlist.status == "success") {
        if (Object.keys(sessionlist).length > 0) {
          const sessionlistDetails = sessionlist.sessionlist.map(
            (sessionlist, Index) => {
              let sno = Index+1;
              if(sessionlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var exam_name = ((sessionlist.exam_name == null)||(sessionlist.exam_name == '')) ?  sessionlist.exam_name = 'N/A' : sessionlist.exam_name;
              var type = ((sessionlist.type == null)||(sessionlist.type == '')) ?  sessionlist.type = 'N/A' : sessionlist.type;
              var duration = ((sessionlist.duration == null)||(sessionlist.duration == '')) ?  'N/A' : sessionlist.duration;
              var tutor_name = ((sessionlist.tutor_name == null)||(sessionlist.tutor_name == '')) ?  'N/A' : sessionlist.tutor_name;
              var catenames = ((sessionlist.catenames == null)||(sessionlist.catenames == '')) ?  'N/A' : sessionlist.catenames;
              var scheduled_on = moment(sessionlist.scheduled_on).format("MMM/DD/YYYY hh:mm a");
              return (
                <tr key={sessionlist.id}>
                  <td>{sno}</td>
                  <td>{exam_name}</td>
                  <td>{type}</td>
                  <td>{tutor_name}</td>
                  <td>{catenames}</td>
                  <td>{duration}</td>
                  <td>{scheduled_on}</td>
                  <td>{lang.Capitalize(status)}</td>                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/sessionmanagement-view/'+sessionlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/sessionmanagement-edit/'+sessionlist.id}} className="" title="Edit">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, sessionlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, sessionlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}    

                     <Link onClick={this.delete.bind(this,sessionlist.id)}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>      
                  </td>
                </tr>
              );
            }
          );
          return sessionlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
    <div className="wrapper"> 
      <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the session?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="sessionmanagement" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Sessions Management</h3>:<h3>{this.state.totalRecord} Session Management</h3>}
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    {/* <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div> */}
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/sessionmanagement-add'>Add</a>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Session Name</th>
                <th>Type</th>
                <th>Tutor</th>
                <th>Category</th>
                <th>Duration (in mins)</th>
				        <th>Scheduled On</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.sessionlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
  <div className="confirm-action-delete">
        <p className="confirm-action-delete-message"></p>
        
        
        <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.selectid)} className="btn">Yes</a>
        <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
        <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
      </div>
      
    </div>
    );
  }
}

export default List;
import React, { Component } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl,tabUrl} from'../Config/Config';
import axios from 'axios';
import MyEditor from './MyEditor';
/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
	    const tabid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			tabid: tabid,
			cat_name:'',
			description:'',
			short_description:'',
			module:'',
			button_text:'',
			image:'',
			image_name: '',
			imagename:'',
			image_preview: '',
			status:'',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedModuleOption:'',
			selectedModulevalue:'',
			tabgalleries: '',
			selectid:'',
			imagepath:'',
			selected_image:'',
			tab_image:'',
		};

		var qs = require('qs');
		axios.get(apiUrl+"tabmanagement/listdetail?id="+tabid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.tablist;
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}

				if(formdata.module !== "" && formdata.module !== undefined){
					this.setState({selectedModuleOption:{value: formdata.module, label: formdata.module}});
				}

				this.setState({cat_name:formdata.cat_name});
				this.setState({description:formdata.description});
				this.setState({short_description:formdata.short_description});
				this.setState({imagename:formdata.image});
				this.setState({button_text:formdata.btn_name});
				this.setState({ tabgalleries: res.data.tabimageslist }, () => {
					console.log(this.state, 'productedit');
					// Render your galleries here using this.state.tabgalleries
				});
						
			}
			
		}); 
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.removeImagebyindex = this.removeImagebyindex.bind(this);
    }

	onChangeHandler(event){		
		let reader = new FileReader();
		const file = event.target.files[0];
		
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
		console.log(this.state, 'evvvve')
	}

	

	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')

	   var callfunc = this;
	   setTimeout(function () {
		this.setState( {
            description: evt
        } );
		}.bind(this),1000);
	
   }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }
    //  handleChange = selectedOption => {
    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'module'){			
			this.setState({selectedModuleOption:selOption})
			this.setState({  selectedModulevalue : selOption.value});
		}
 	};
	componentDidMount() {
		document.title = PageTitle('Tab Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				var qs = require('qs');
				const formPayload = this.state;
			    var status, module = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedModulevalue === '' && formPayload.selectedModulevalue!== 0){
					module =formPayload.selectedModuleOption.value;
				}else{
					module = formPayload.selectedModulevalue;
				}
				
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					tabid       : formPayload.tabid,
					cat_name     : formPayload.cat_name,
					description    : formPayload.description,
					short_description    : formPayload.short_description,
					image: formPayload.image,
					button_text: formPayload.button_text,
					module     : module,
					status       :  status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				console.log(formData,'formData');

				var filesdata = this.state.tab_image;
				var galleryimg = this.state.galleryimg;
				if(filesdata){
				for (var i in filesdata) {
					formData.append('multiple_images[]',filesdata[i])
				}
			} 

				axios.post(apiUrl+"tabmanagement/edit",formData).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/tabmanagement');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {cat_name,description,short_description, module} = this.state;

		let errors = 0;
		if (!cat_name) {			
			errors++;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			
			$('.errorcat_name').html('');
		}

		if (!description) {			
			errors++;
			$('.errordesc').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			
			$('.errordesc').html('');
		}

		if (!short_description) {			
			errors++;
			$('.errorshort').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			
			$('.errorshort').html('');
		}


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	 
      }

	  confirmPopup = (id,imagepath,selected_image,type) => {
		this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
		$('.confirm-action').addClass('show');		
	}
	deleteimage(){
		var qs = require('qs');
		var postObject = {
		  admin_id : localStorage.getItem("admin_id"),
		  gallery_id  :this.state.selectid,
		  id  :this.state.tabId,
		  imagepath  :this.state.imagepath,
		  selected_image  :this.state.selected_image,
		  type: this.state.type
		};
		axios.post(apiUrl+"tabmanagement/deleteimage",qs.stringify(postObject)).then(res => {      
			  if(res.data.status === 'success'){
				if(this.state.type == 'product'){
					this.setState({productgalleries:res.data.productgalleries})
				}else{
					this.setState({tryoutgalleries:res.data.tryoutgalleries})
				}
				this.setState({ tabgalleries: res.data.productgalleries }, () => {
					// Render your galleries here using this.state.tabgalleries
				});
				$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
				setTimeout(function() {
					$('.success_message').html('');   
				}.bind(this),3000);
			  }
		}); 
	}
	Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deleteimage();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}  
	}
	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			
			this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		}
		removeImagebyindex = (indexvalue) => {
		   
			var list = [...this.imageArray];
			this.imageArray = [];
			this.fileArray = [];
			list.splice(indexvalue, 1);
		   
			for (let i = 0; i < list.length; i++) {
			  this.imageArray.push(list[i]);
			  this.fileArray.push(URL.createObjectURL(list[i]));
			}
		  
			  this.setState({ tab_image: this.imageArray })	
	  }
	
  render() {

  	const {selectedOption,selectedModuleOption} = this.state;

	  let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))

	let loaderimg = '';
	let preview = '';
	var current = this;
	

	const imagepre = this.state.image_preview;

	if (this.state.tabid!=='' && this.state.imagename!== null && this.state.imagename!== '' && this.state.imagename!== undefined ) {
		loaderimg = tabUrl + '/'+this.state.imagename;
		preview = <img className="img_class" src={loaderimg} alt="" />;
	// alert('hh');
	}else{
		preview = <img className="img_class" src={imagepre} alt="" />;
	}
   
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tabmanagement" />  	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/tabmanagement'>Back</a> 
			</div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Tab Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>
				
				<div className="form-group">
					<label>Description<span class="required" style={{ color: "red" }} >*</span></label>
					{/* <CKEditor initData={this.state.description} /> */}
					<MyEditor id="description" initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
						{/* <textarea className="form-control" name="description" onChange={this.handleInputChange}  value={this.state.description} id="" rows="5"  autoComplete="off" /> */}
						<div className="errordesc"></div>
				</div>
				 
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
				
					<div className="errorloaderimage"></div>  
				</div> 

				<div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
				</div>
				<div className="form-row">
									{/* <div className="form-left"> */}
										{this.state.tabgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Image Galleries</label>
												<div className='gallery-container'>
													{this.state.tabgalleries?.map((item) => (
													<div className='gallery-inner' key={item.id}>
														<img width="100px" height="100px" src={tabUrl+'/'+'tabimages'+'/'+item.tab_image} alt={`Image ${item.id}`}  />
														 <Link onClick={this.confirmPopup.bind(this, item.id,item.tab_image_path,item.tab_image,'tab')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									{/* </div> */}
								</div>

				
				   
				</div>
			<div className="form-right">
				<div className="form-group">
					<label>Module:</label>
				    {/* <input readOnly type="text" name="module" onChange={this.handleInputChange} className="form-control" value={this.state.module} /> */}
					<Select isDisabled
						value={selectedModuleOption?selectedModuleOption:""}
							options={lang.common.tab_module_option} 
							onChange={this.handleChange.bind(this, 'module')}
							/>
					<div className="errormodule"></div>
				</div>

				<div className="form-group">
					<label>Short Description<span class="required" style={{ color: "red" }} >*</span></label>
					{/* <MyEditor id="short_description" initialData={this.state.short_description} onEditorChange={this.onEditorChange}> </MyEditor> */}
						<textarea className="form-control" name="short_description" onChange={this.handleInputChange}  value={this.state.short_description} id="" rows="5"  autoComplete="off" />
						<div className="errorshort"></div>
				</div>
					
				<div className="form-group">
					<label>Button Text</label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
				</div>

				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						<Select 
						value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							onChange={this.handleChange.bind(this, 'status')}
							/>
				</div>
					
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);
import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl } from'../Config/Config';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class CompanyClone extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);
    console.log(props)	
   const CompanyId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {		
			company_id: CompanyId,
			userlist: [],
		  fontlist:[],
			Loading:false,
            listIds:[]
		};

    document.title = PageTitle('Company Edit');

        var qs = require('qs');
		var company_id = this.state.company_id;
		axios.get(apiUrl+"company/companyclonedetail?companyid="+company_id).then(res => {
            if(res.data.status == 'success'){
                // this.setState({ userlist : res.data.userlist});
                this.setState({ fontlist : res.data.fontlist});
			}
		}); 
    }
    userlist() {
        var userlist = this.state.userlist;
        if (userlist != "undefined" && userlist != null) {
            if (Object.keys(userlist).length > 0) {
              const userlistDetails = userlist.map(
                (userlist, Index) => {
                  let sno = Index+1;
                
                 
                  return (
                    <p>{sno}. {userlist.username} - {userlist.email}</p>
                  );
                }
              );
              return userlistDetails;
            }else{
                return (
                    <p>
                      No Records
                    </p>
                );
            }
          } else {
            return (
                <p>
                  No Records
                </p>
            );
          }
      }
      specialistlist() {
        var specialistlist = this.state.specialistlist;
        if (specialistlist != "undefined" && specialistlist != null) {
            if (Object.keys(specialistlist).length > 0) {
              const specialistlistDetails = specialistlist.map(
                (specialistlist, Index) => {
                  let sno = Index+1;
                
                 
                  return (
                    <p>{sno}. {specialistlist.cat_name}</p>
                  );
                }
              );
              return specialistlistDetails;
            }else{
                return (
                    <p>
                      No Records
                    </p>
                );
            }
          } else {
            return (
                <p>
                  No Records
                </p>
            );
          }
      }
      fontlist() {
        var fontlist = this.state.fontlist;
        if (fontlist != "undefined" && fontlist != null) {
            if (Object.keys(fontlist).length > 0) {
              const fontdetails = fontlist.map(
                (fonts, Index) => {
                  let sno = Index+1;                 
                  return (
                    <p>{sno}.{ fonts.tf_name}</p>
                  );
                }   
              );
              return fontdetails;
            }else{
                return (
                    <p>
                      No Records
                    </p>
                );
            }
          } else {
            return (
                <p>
                  No Records
                </p>
            );
          }
      }
 
     
    handleCheckbox = (id) =>{
  
        var listidArr = [...this.state.listIds];
        const index = listidArr.findIndex(list => list === id);
        if(index > -1) {
          
            listidArr = [...listidArr.slice(0, index), ...listidArr.slice(index + 1)]
        } else {
            listidArr.push(id);
        }
        this.setState({listIds: listidArr});
    }
    
  handleSubmit = () => {
	const formdata = this.state;
	var qs = require('qs');
    const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    };
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		company_id : formdata.company_id,
		clone_list : formdata.listIds,
	}

  
	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"company/setcompanycopy",formData,config).then(res => {
    if(res.data.status === "success"){
      $('.success_message').html('<div class="status_sucess"><h3>Company clone successfully</h3></div>');
          setTimeout(
          function() {
            $('.success_message').html(this.props.navigate('/company'));
            
          }
          .bind(this),
          3000
          );
    }else{
      $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
      
      setTimeout(
      function() {
        $('.success_message').html('');
      }
      .bind(this),
      3000
      );
    }
	});
  }
    render() {
        
        console.log(this.state.listIds,'listIds');  
        return (
            <div className="wrapper"> 
       
            <Header />
            <MenuSidebar currentpage="companylist" />  
      
          <div id="location-error"></div>
          <div className="content">	
              <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
                  <div className="form-wrapper leaa-admin-head card">
                  <div className="success_message"></div>
                  <div className="title">
                      <h4>Company Clone</h4>				
                  </div>
                   <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>             
                   <div className="row">
                   <p>Choose the module to get clone</p>
                 
                    </div>
                   <div className="form-row">
                  <div className="form-left">
                        
                      <div className="form-group">

                      <input  type="checkbox" value="user" onChange={this.handleCheckbox.bind(this,'font')} />	<label>Font List:</label>
                          <div className='pl-5'>
                           {this.fontlist()}
                          </div>
                      </div>
                    {/* <div className="form-group">					
                        <input  type="checkbox" value="specialist" onChange={this.handleCheckbox.bind(this,'specialist')} />	<label>Specialist List: </label>
                          <div className='pl-5'>
                          {this.specialistlist()}
                          </div>
                      </div>
                    <div className="form-group">
                        
                    <input  type="checkbox" value="productcategory" onChange={this.handleCheckbox.bind(this,'productcategory')} />	<label>Product Category List:</label>
                        <div className='pl-5'>
                        {this.productcategorylist()}
                          </div>
                        
                    </div>
                    <div className="form-group">
                        
                    <input  type="checkbox" value="prescription" onChange={this.handleCheckbox.bind(this,'prescription')} />	<label>Prescription List:</label>
                        <div className='pl-5'>
                        {this.prescriptionlist()}
                          </div>
                        
                    </div> */}
                     
                </div>
                  {/*   <div className="form-left">
                        <div className="form-group">					
                        <input  type="checkbox" value="doctor" onChange={this.handleCheckbox.bind(this,'doctor')} />	<label>Doctor/Provider List:</label>
                                <div className='pl-5'>
                                {this.doctorlist()}
                                </div>
                        </div>
                        <div className="form-group">					
                        <input  type="checkbox" value="category" onChange={this.handleCheckbox.bind(this,'category')} />	<label>Category List:</label>
                            <div className='pl-5'>
                            {this.categorylist()}
                            </div>
                        </div>  
                        <div className="form-group">					
                        <input  type="checkbox" value="product" onChange={this.handleCheckbox.bind(this,'product')} />	<label>Product List:</label>
                            <div className='pl-5'>
                            {this.productlist()}
                            </div>
                        </div>  
			              </div>	 */}
               
                  </div>	
              {/* ----------------- */}
                  <div className="btn-group export">	
                      <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
                        Clone                                          
                     </button>
                   
                  </div>
                  </form>
                  </div>
                      
              </div>	
          </div>
                   
          </div>
          );
  }
}

export default  withRouter(CompanyClone);
import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";

class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			admin_company_name:'',			
			admin_fname:'',
			admin_username:'',
			admin_contact:'',
			admin_email_address:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			domain_count:1
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Company Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,
					domain_count:formPayload.domain_count,
					admin_status:status,
				};
			   axios.post(apiUrl+"company/add",qs.stringify(postObject)).then(res => {
				 
				 if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							admin_company_name:'',			
							admin_fname:'',
							admin_username:'',
							admin_contact:'',
							admin_email_address:'',
							selectedvalue: '',
							domain_count:''
						});
						setTimeout(
						function() {
							//$('.success_message').html(this.props.history.push('/company'));
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
			   }); 
			}
	}

	validateForm() {
		const {admin_company_name,admin_username,admin_email_address,admin_contact,domain_count} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!admin_company_name) {
			formIsValid = false;
			$('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_company_name){
			$('.erroradmin_company_name').html('');
		}
		
		if (!admin_username) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			$('.erroradmin_username').html('');
		}		
		
		if (!admin_email_address) {
			formIsValid = false;
			
			$('.erroradmin_email_address').html('<span class="errorspan">Email is required</span>');
			}else if(admin_email_address){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(admin_email_address)) 
			{
			  $('.erroradmin_email_address').html('');
			}else{
				formIsValid = false;
			  
			$('.erroradmin_email_address').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!admin_contact) {
			formIsValid = false;
			
			$('.erroradmin_contact').html('<span class="errorspan">Contact is required</span>');
		  }else if(admin_contact){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(admin_contact)) {
				$('.erroradmin_contact').html('');
				}else if(indiapattern.test(admin_contact)){
				$('.erroradmin_contact').html('');
				}else{
					formIsValid = false;
				
					 $('.erroradmin_contact').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}
		// if (!admin_contact) {
		// 	formIsValid = false;
		// 	$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		// }else if(admin_contact){
		// 	$('.erroradmin_contact').html('');
		// }

		// if (!admin_email_address) {
		// 	formIsValid = false;
		// 	$('.erroradmin_email_address').html('<span class="errorspan">Please fill the field</span>');
		// }else if(admin_email_address){
		// 	$('.erroradmin_email_address').html('');
		// }

		if (!domain_count) {
			formIsValid = false;
			$('.errordomain_count').html('<span class="errorspan">Please fill the field</span>');
		}else if(domain_count){
			$('.errordomain_count').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

 
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="companylist" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/company'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Company</h4>				
			</div>
			
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Company Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="admin_company_name" onChange={this.handleInputChange} className="form-control" value={this.state.admin_company_name} />
					<div className="erroradmin_company_name"></div>
				</div>
							
				<div className="form-group">					
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="admin_contact" onChange={this.handleInputChange} className="form-control" value={this.state.admin_contact} />
					<div className="erroradmin_contact"></div>
				</div>

				<div className="form-group">					
					<label>Domain Restriction Count<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="domain_count" onChange={this.handleInputChange} className="form-control" value={this.state.domain_count} />
					<div className="errordomain_count"></div>
				</div>

				
			</div>
			<div className="form-right">	
			
				<div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
					<div className="erroradmin_username"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
					<div className="erroradmin_email_address"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
			   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Loading</span>:<span>Submit</span>
                                        } 
								</button>

			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default Add;
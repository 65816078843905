import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { PageTitle } from "../Helpers/SettingHelper";
import $ from 'jquery';
/* import { GET_VIEWadlist } from '../../actions'; */
import { apiUrl, adUrl} from'../Config/Config';
import axios from 'axios';
import parse from 'html-react-parser';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      id: id,
      adlistview:'',
      imagename:''
    };

    axios.get(apiUrl+"advertisement/listdetail?id="+id).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.adlist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({adlistview:res.data.adlist});
      this.setState({imagename:formdata.image});	
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Advertisement View');
   }


   
  render() {
    
    
    // var module = ((this.state.adlistview.module == null)||(this.state.adlistview.module == '')) ?  this.state.adlistview.module = 'N/A' : this.state.adlistview.module;
    var amount = ((this.state.adlistview.amount === null)||(this.state.adlistview.amount === '')) ?  this.state.adlistview.amount = 'N/A' : '$' + this.state.adlistview.amount;
    var name = ((this.state.adlistview.name === null)||(this.state.adlistview.name === '')) ?  this.state.adlistview.name = 'N/A' : this.state.adlistview.name;
    // var description = this.removeTags(this.state.adlistview.description);
    var description = ((this.state.adlistview.description === null)||(this.state.adlistview.description === '')) ?  'N/A' : this.state.adlistview.description;

    var short_desc = ((this.state.adlistview.short_desc === null)||(this.state.adlistview.short_desc === '')) ?  'N/A' : this.state.adlistview.short_desc;

    
    // const regex = /(<([^>]+)>)/gi;
    // description = description.replace(regex, "");

    
    if(this.state.adlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }

    var icon = "";
    if((this.state.adlistview.image == null)||(this.state.adlistview.image == '')){
      icon = "N/A";
    }else{
      var urll = adUrl + '/' + this.state.adlistview.image;
      icon = <img width="80px" src={urll}/>;
    }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="advertisement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/advertisement'>Back</a> 
		          	</div>
                <div className="title">
                  <h3>Advertisement Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Title<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{name}</p>
                          </div>
                        </div>
                        {description!=null&&description!=undefined&&<div className="view-details">
                          <div className="view-details-one">
                              <p><b>Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{parse(description)}</p>
                          </div>
                        </div>}
                        {short_desc!=null&&short_desc!=undefined&&<div className="view-details">
                          <div className="view-details-one">
                              <p><b>Short Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{parse(short_desc)}</p>
                          </div>
                        </div>}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Link Type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.adlistview.linktype}</p>
                          </div>
                        </div>

                        {this.state.adlistview.linktype=='external'&&<div className="view-details">
                          <div className="view-details-one">
                              <p><b>Link <span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.adlistview.link}</p>
                          </div>
                        </div>}
                        {this.state.adlistview.linktype=='product'&&<div className="view-details">
                          <div className="view-details-one">
                              <p><b>Product <span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.adlistview.product_name}</p>
                          </div>
                        </div>}

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Button text <span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.adlistview.button_text}</p>
                          </div>
                        </div>


                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Amount<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{amount}</p>
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Image<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{icon}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                        



                    </div>
                </div>
                 
                  
               
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

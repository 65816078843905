import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWtutorlist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      userid: userid,
      tutorlistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"tutor/listdetail?id="+userid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.tutorlist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({tutorlistview:res.data.tutorlist});
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Tutor View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
    

    var mobileno = ((this.state.tutorlistview.mobile === null)||((this.state.tutorlistview.mobile === ''))) ?  this.state.tutorlistview.mobile = 'N/A' : this.state.tutorlistview.mobile;
    var address = ((this.state.tutorlistview.address === null)||((this.state.tutorlistview.address === ''))) ?  this.state.tutorlistview.address = 'N/A' : this.state.tutorlistview.address;
    var institute_name = ((this.state.tutorlistview.institute_name === null)||((this.state.tutorlistview.institute_name === ''))) ?  this.state.tutorlistview.institute_name = 'N/A' : this.state.tutorlistview.institute_name;
    // var designation = ((this.state.tutorlistview.designation === null)||((this.state.tutorlistview.designation === ''))) ?  this.state.tutorlistview.designation = 'N/A' : this.state.tutorlistview.designation;
    // var university_studied = ((this.state.tutorlistview.university_studied === null)||((this.state.tutorlistview.university_studied === ''))) ?  this.state.tutorlistview.university_studied = 'N/A' : this.state.tutorlistview.university_studied;
    // var workplace = ((this.state.tutorlistview.workplace === null)||((this.state.tutorlistview.workplace === ''))) ?  this.state.tutorlistview.workplace = 'N/A' : this.state.tutorlistview.workplace;

    var lastname = ((this.state.tutorlistview.lastname === null)||(this.state.tutorlistview.lastname === "")) ?  this.state.tutorlistview.lastname = 'N/A' : this.state.tutorlistview.lastname;

    if(this.state.tutorlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="tutor" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/tutor'>Back</a> 
		          	</div>
                <div className="title">
                  <h3>Tutor Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.tutorlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.tutorlistview.firstname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{lastname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.tutorlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Address<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{address}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Institute<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{institute_name}</p>
                          </div>                         
                        </div>
                        {/* 
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Designation<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{designation}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>University Studied<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{university_studied}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Workplace<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{workplace}</p>
                          </div>
                          
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.tutorlistview.domain_name}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                        



                    </div>
                </div>
                 
                  
               
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

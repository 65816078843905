import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Geocode from "react-geocode";
/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
	    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			userid: userid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			address:'',
			// graduation:'',
			// designation:'',
			// university_studied:'',
			// workplace:'',
			password:'',
			status:'',
			changepassword:'',
			confirm_password: '',
			new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			selectedDomainOption:'',
			selectedDomainvalue:'',
			selectedInsOption:'',
			selectedInsvalue:'',
			maxCharacterLimit : 10,
		};
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
		var qs = require('qs');
		axios.get(apiUrl+"tutor/listdetail?id="+userid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.tutorlist;
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}

				if(formdata.institute !== '' && formdata.institute !== 'NULL' && formdata.institute !== 0){								
					var ins_id = formdata.institute;		
					axios.get(apiUrl+"institute/listdetail?id="+ins_id).then(res => {
						if(res.data.status == "success"){					
							let institutelist = res.data.institutedata;
							this.setState({selectedInsOption:{value: institutelist['id'], label: institutelist['institute']}});					
							this.setState({selectedInsvalue:institutelist['id']});					
						}
					});
				}

				this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobile});
				this.setState({address:formdata.address});
				// this.setState({graduation:formdata.graduation});
				// this.setState({designation:formdata.designation});
				// this.setState({university_studied:formdata.university_studied});
				// this.setState({workplace:formdata.workplace});
				this.setState({userid: formdata.id});
				var postObject = {
					id: res.data.tutorlist.domain_id,			
				};
		
				axios.post(apiUrl+"tutor/domainlist",qs.stringify(postObject)).then(res => {
					if(res.data.status == "success"){					
						let domaindata = res.data.domainlist;
						this.setState({selectedDomainOption:{value: domaindata[0]['domain_id'], label: domaindata[0]['domain_name']}});					
						this.setState({selectedDomainvalue:domaindata[0]['domain_id']});					
					}
				});
			}
			
		}); 
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   
    }

   handleInputChange(event) {
		// console.log(event.target.value, 'evvvv')
		const {name, value} = event.target;      
		this.setState({
          	[name]: value
        });

		const google = window.google;
		const callfunc = this;

		if(name == 'address'){

			var input = document.getElementById('address');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
		   // document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress = place.formatted_address;
			
			callfunc.setState({address: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			});		
	     }

		// console.log(callfunc.state.address, 'stttt')
     
    }

	getgoecoder(input, name){

		$('#address').val('');
			
		if(name == 'address'){ 
			Geocode.fromAddress(input).then(
			  response => {
				console.log(response,'response')
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					address: response.results[0].formatted_address
				});	
				
				/* document.getElementById('address').value = response.results[0].formatted_address;
				$('#address').val(response.results[0].formatted_address) */;				  
			
			  },
			  error => {
				console.error(error);
			  }
			);

			
		}

	}
	/*
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };*/

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'inst'){			
			this.setState({selectedInsOption:selOption})
			this.setState({  selectedInsvalue : selOption.value});
		}
 	};

	componentDidMount() {
		document.title = PageTitle('Tutor Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status, institute = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedInsvalue === '' && formPayload.selectedInsvalue!== 0){
					institute =formPayload.selectedInsOption.value;
					}else{
					institute = formPayload.selectedInsvalue;
					}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id    : formPayload.selectedDomainvalue,
					username     : formPayload.username,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					address      : formPayload.address,
					institute	 : institute,
					// graduation     : formPayload.graduation,
					// designation     : formPayload.designation,
					// university_studied     : formPayload.university_studied,
					// workplace     : formPayload.workplace,
					/*currentpswd  : formPayload.password,*/
					changepassword:formPayload.changepassword, 
					status       :  status,
				};

				
				axios.post(apiUrl+"tutor/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.tutorlist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');

								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/tutor');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, changepassword, confirm_password,selectedDomainvalue,maxCharacterLimit,address/*,graduation,designation,university_studied,workplace*/} = this.state;
      	//let formIsValid = true;
		  const nameRegex = /^[A-Za-z\s]+$/;

		let errors = 0;
		if (!username) {
			
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			
			$('.errorusername').html('');
		}
		

		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid first name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}

		 if(lastname){
			if (!nameRegex.test(lastname)) {
				errors++;
			$('.errorlastname').html('<span class="errorspan">Please enter the valid last name</span>');
			}else{
				$('.errorlastname').html('');
			}
			
		}
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
			if (!selectedDomainvalue) {
				errors++;
				$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		   }else if(selectedDomainvalue){
				$('.errorassign_domain').html('');
			}

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

				if (!address) {
					errors++;
					$('.erroraddress').html('<span class="errorspan">Please fill the field</span>');
				}else if(address){					
					$('.erroraddress').html('');								
				}
				/*
				if (!university_studied) {
					errors++;
					$('.erroruniversity').html('<span class="errorspan">Please fill the field</span>');
				}else if(university_studied){					
					$('.erroruniversity').html('');								
				}
	
				if (!graduation) {
					errors++;
					$('.errorgraduation').html('<span class="errorspan">Please fill the field</span>');
				}else if(graduation){					
					$('.errorgraduation').html('');								
				}

				if (!designation) {
					errors++;
					$('.errordesignation').html('<span class="errorspan">Please fill the field</span>');
				}else if(designation){					
					$('.errordesignation').html('');								
				}

				if (!workplace) {
					errors++;
					$('.errorworkplace').html('<span class="errorspan">Please fill the field</span>');
				}else if(workplace){					
					$('.errorworkplace').html('');								
				}*/

		if(changepassword !=''){
			// if (changepassword.match(/[a-z]/g) && changepassword.match( 
            //         /[A-Z]/g) && changepassword.match( 
            //         /[0-9]/g) && changepassword.match( 
            //         /[^a-zA-Z\d]/g) && changepassword.length >= 8){
		 	// 			$('.errorpassword').html('');
		 	// }else{
			// 	errors++;
			// 	$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 	// }
		}
		/*
		if(confirm_password !=''){
		 if (confirm_password.match(/[a-z]/g) && confirm_password.match( 
                    /[A-Z]/g) && confirm_password.match( 
                    /[0-9]/g) && confirm_password.match( 
                    /[^a-zA-Z\d]/g) && confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		 
		if(changepassword !== confirm_password){
          	errors++;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }
		
		}*/
		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.userdetails !== this.props.userdetails){
    		if(Object.keys(Props.userdetails).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.userdetails[0].status === "success"){

						const formdata = Props.userdetails[0].tutorlist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobileno});
						this.setState({address:formdata.address});
						// this.setState({graduation:formdata.graduation});
						// this.setState({designation:formdata.designation});
						// this.setState({university_studied:formdata.university_studied});
						// this.setState({workplace:formdata.workplace});
						this.setState({userid: formdata.id});
					   
					}
    		}
    	}

	
         if(Props.useredit !== this.props.useredit){
    		if(Object.keys(Props.useredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.useredit[0].status === "success"){

    			const formdata = Props.useredit[0].tutorlist;
				console.log(formdata);
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({userid: formdata.id});
				this.setState({address:formdata.address});
				// this.setState({graduation:formdata.graduation});
				// this.setState({designation:formdata.designation});
				// this.setState({university_studied:formdata.university_studied});
				// this.setState({workplace:formdata.workplace});
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							//$('.success_message').html(this.props.history.push('/tutor'));
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}   
      }

	  handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}


	
  render() {

  	const {selectedOption,selectedDomainOption,selectedInsOption} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tutor" />  	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/tutor'>Back</a> 
			</div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>

				{/* <div className="form-group">
					<label>Graduation<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="graduation" onChange={this.handleInputChange} className="form-control" value={this.state.graduation} autoComplete="off" />
					<div className="errorgraduation"></div>
				</div>
				
				<div className="form-group">
					<label>University Studied<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="university_studied" onChange={this.handleInputChange} className="form-control" value={this.state.university_studied} autoComplete="off" />
					<div className="erroruniversity"></div>
				</div> */}
				
				{/*<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.chnagepassword}/>
					<div className="errorchangepaswd"></div>
				</div>*/}
				
				<div className="form-group eye-pad">
                    <label>Password</label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>

				  <div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           	</div> 
				  
				  
				 
				
				   
				</div>
			<div className="form-right">
				<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				
				<div className="form-group">
					<label>Address<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" className="form-control input-focus" placeholder="" name="address" id="address" value={this.state.address} onChange={this.handleInputChange} autoComplete="off" />
					{/* <input type="text" name="address" onChange={this.handleInputChange} className="form-control" value={this.state.address} /> */}
					{/* <AutoComplete
					name="address"
					className="form-control"
					onChange={this.handleInputChange}
					apiKey="AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc"
					onPlaceSelected={this.state.address}
					/> */}
					<div className="erroraddress"></div>
				</div>

				<div className="form-group">					
					<label>Institute</label>
						<Select 
						value={selectedInsOption?selectedInsOption:""}
							options={lang.common.institute_option} 
							onChange={this.handleChange.bind(this, 'inst')}
							/>
				</div>

				<div className="default-font">
					<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange.bind(this, 'status')}
								/>
					</div>
				</div>
				

				{/* <div className="form-group">
					<label>Designation<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="designation" onChange={this.handleInputChange} className="form-control" value={this.state.designation} autoComplete="off" />
					<div className="errordesignation"></div>
				</div>

				<div className="form-group">
					<label>Workplace<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="workplace" onChange={this.handleInputChange} className="form-control" value={this.state.workplace} autoComplete="off" />
					<div className="errorworkplace"></div>
				</div>

				<div className="form-group eye-pad">
                    <label>Confirm Password<span class="required" style={{ color: "red" }} >*</span></label>
                    <input type={this.state.confrim_pass_type} name="confirm_password" id="confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                  </div>
                   <div className="errorchangepaswd"></div> */}


					
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const cateid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      cateid: cateid,
      institutelistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"institute/listdetail?id="+cateid).then(res => {
        if(res.data.status == 'success'){
          const formdata = res.data.institutelist;
          this.setState({institutelistview:res.data.institutelist});						
        }			
      }); 
    }

   
   componentDidMount() {
     document.title = PageTitle('Institute View');
   }
   
  render() {   

    var parentcate_name = ((this.state.institutelistview.parentcate_name === null)||((this.state.institutelistview.parentcate_name === ''))) ?  this.state.institutelistview.parentcate_name = 'N/A' : this.state.institutelistview.parentcate_name;
    
    if(this.state.institutelistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="institute" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/institute'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Institute Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Institute Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.institutelistview.institute}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.institutelistview.email}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.institutelistview.mobile}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Username<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.institutelistview.username}</p>
                          </div>
                        </div>

                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Password<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.institutelistview.orgpwd}</p>
                          </div>
                        </div> */}
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>
                    </div>
                </div>               
              </div>  
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Table
} from "react-bootstrap";
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

/* import { GET_FONTLIST, GET_DELETEFONT } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };
   axios.post(apiUrl+"font/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
      this.setState({ totalRecord : res.data.records_count, fontlist: res.data.fontlist}, function() {
        this.fontlist();
      });
     }
   }); 

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Fonts');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }

      this.listloadedData()
   }

   listloadedData(postobject='') {
    var qs = require('qs');
     if(postobject!=''){
      var postobject = postobject
     }else{
      var postobject = {
        activePage: this.state.activePage,
        search_all: '',
        admin_id: localStorage.getItem('admin_id')  
        };
     }
    
   axios.post(apiUrl+"font/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
      this.setState({ totalRecord : res.data.records_count, fontlist: res.data.fontlist}, function() {
        this.fontlist();
      });
     }
   }); 
  }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    // this.props.getFontList(qs.stringify(postobject))
    this.listloadedData(postobject)
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          // this.props.getFontList(qs.stringify(postObject));
          this.listloadedData(postObject)
        
    }

    delete(id){
      this.setState({idd : id})
      $('.confirm-action-delete-message').html('Are you sure! you want to delete?');	
      $('.confirm-action-delete').addClass('show');	
     }
  
     deleteConfirm =(confirmstatus,id)=>{
      if(confirmstatus == 'yes'){
        $('.confirm-action-delete').removeClass('show');
        var qs = require('qs');       
        var postObject = { 
          column: 'admin_id',         
          font_id: id,   
          activePage:this.state.activePage,  
          admin_id:localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"font/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){             
            this.listloadedData()
          }
        });  
      }else{
        $('.confirm-action-delete').removeClass('show');	
      }
    }


/*
  deletefont(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          font_id  :id,
          admin_id  : admin_id
        };

      axios.post(apiUrl+"font/delete",qs.stringify(postObject)).then(res => {
     
        if(res.data.status === 'success'){
          this.setState({
            fontlist: res.data.fontlist,
            });
            window.location.reload(); 
            }
      }); 
}*/
  fontlist() {
    var fontlist = this.state.fontlist;
    if (fontlist != "undefined" && fontlist != null) {
        if (Object.keys(fontlist).length > 0) {
          const fontlistDetails = fontlist.map(
            (fontlist, Index) => {
              let sno = Index+1;
              if(fontlist.tf_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={fontlist.tf_id}>
                <td>{sno}</td>
                  <td>
                  {fontlist.tf_name}
                  </td>
                  <td>{status}</td>
                  <td>
                   <Link to={{ pathname: '/font-edit/'+fontlist.tf_id}} className="" title="edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                     {/* <Link onClick={this.deletefont.bind(this,fontlist.tf_id)} className="" title="delete">  <i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                     <Link onClick={this.delete.bind(this,fontlist.tf_id)}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return fontlistDetails ;
        }  else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="fonts" />  
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
            <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className="title">
                  <h3>Fonts</h3>
                </div>

                 {/* <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> */}
              <div className="add_button">  
                    <a className="" href='/font-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Font Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.fontlist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>

      <div className="confirm-action-delete">
          <p className="confirm-action-delete-message"></p>
          
          
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.idd)} className="btn">Yes</a>
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
          <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
        </div>

      
    </div>
    );
  }
}


export default List;
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import MyEditor from './MyEditor';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			product_name:'',
			price:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			description:'',		  
			store_name: '',
			description:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			author_name:'',
			quantity:'',
			saleprice:'',
			rating:'',
			reviews:''
		
			
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };
  
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  }

  	
	onEditorChange( evt ) {		
		var callfunc = this;
		setTimeout(function () {
		 this.setState( {
			 description: evt
		 });
		 }.bind(this),1000);
	 
	}

	
	componentDidMount() {
		
	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		};
		reader.readAsDataURL(file);
	}

	imageRemove = () => {
		// Your logic to remove the selected image
		this.setState({
		  image_name: '',
		  image_preview: '',
		  image: null
		});
	  };
  handleSubmit = () => {
	if(this.validateForm()){
		
	const formdata = this.state;
	var qs = require('qs');
	var status = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

/* 	if(formdata.selectedproductvalue === '')
	{
		var product_type =formdata.selectedProductOption.value;
	}else{
		var product_type = formdata.selectedproductvalue;
	} */

	var img_name = this.state.image;
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		product_name : formdata.product_name,
		price: formdata.price,
		author_name: formdata.author_name,
		quantity: formdata.quantity,
		saleprice: formdata.saleprice,
		review:formdata.reviews,
		rating:formdata.rating,
		
		description: formdata.description,
		image : formdata.image,
		status: status,
		/* product_type: product_type, */
	}
	

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"product/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/product')
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
	}
  }
	validateForm() {
		const {product_name,author_name,quantity,
		price,saleprice, description,
		// quantity,
		prescription_detail} = this.state;

		let errors = {};
      	let formIsValid = true;

		  
		
		if(!description){
        	formIsValid = false;
        	$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errordescription').html('');
		}

		if(!author_name){
        	formIsValid = false;
        	$('.errorauthor_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorauthor_name').html('');
		}
	
		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}
		if (!price) {
			formIsValid = false;
			$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else if(price){
			$('.errorprice').html('');
		}
		
		
		if (!quantity) {
			formIsValid = false;
			$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
		}else if(quantity){
			$('.errorquantity').html('');
		}
     
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	
  render() {
	const navigate = this.props.navigate;
	let preview = '';
	const {selectedOption} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Product" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div> 
			<div className="title">
				<h4>Add Product</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name}/>
					<div className="errorproduct_name"></div>
				</div>
				<div className="form-group">
					<label>Author Name<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="text" name="author_name" onChange={this.handleInputChange} className="form-control" value={this.state.author_name}/>
					<div className="errorauthor_name"></div>
				</div>
				<div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div> 
				<div className="form-group">
					<label>Rating  </label>
				    <input type="number" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating}/>
					<div className="errorrating"></div>
				</div> 
				<div className="form-group">
					<label>Review  </label>
				    <input type="number" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews}/>
					<div className="errorreviews"></div>
				</div> 
				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> </label>
						<Select 
							name="status"
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange}
						/>
	           	</div> 
				
				</div>
			
			<div className="form-right">

				<div className="form-group pricediv">
					<label>Price ($)<span class="required" style={{ color: "red" }} > * </span>  </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>
				<div className="form-group pricediv">
					<label>Sale Price ($)  </label>
				    <input type="number" name="saleprice" onChange={this.handleInputChange} className="form-control" value={this.state.saleprice}/>
					<div className="errorsaleprice"></div>
				</div>
				<div className="form-group">					
					<label>Product Description<span class="required " style={{ color: "red" }} > * </span></label>
					<MyEditor id="description" className="ck-right"  initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.prescription_detail} /> */}
					<div className="errordescription"></div>
				</div>
				
				
				<div className="form-group">
					<label>Image</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
					<div className="form-group">
						{preview}
						
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						</div>
					)}  
				</div> 
				
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));
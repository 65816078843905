import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl,tabUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          tablist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
         
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"tabmanagement/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  tablist: res.data, totalRecord: res.data.records_count}, function() {
         this.tablist();
       });
   }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

activatetab(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          tabid  :this.state.selectid,
		      status: stat
        };
        axios.post(apiUrl+"tabmanagement/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}


confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activatetab();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}



delete(id){
  this.setState({tabid : id})
  $('.confirm-action-delete-message').html('Are you sure! you want to delete?');	
  $('.confirm-action-delete').addClass('show');	
 }

 deleteConfirm =(confirmstatus,id)=>{
  if(confirmstatus == 'yes'){
    $('.confirm-action-delete').removeClass('show');
    var qs = require('qs');       
    var postObject = { 
      column: 'admin_id',         
      id: id,   
      activePage:this.state.activePage,  
      admin_id:localStorage.getItem("admin_id")
    };
    axios.post(apiUrl+"tabmanagement/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){             
        this.listloadedData()
      }
    });  
  }else{
    $('.confirm-action-delete').removeClass('show');	
  }
}



  tablist() {

    var tablist = this.state.tablist;
    if (tablist != "undefined" && tablist != null) {
      if (tablist.status == "success") {
        if (Object.keys(tablist).length > 0) {
          const tablistDetails = tablist.tablist.map(
            (tablist, Index) => {
              let sno = Index+1;
              if(tablist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              
              // var module = ((tablist.module == null)||(tablist.module == '')) ?  tablist.module = 'N/A' : tablist.module;
              var description = ((tablist.description == null)||(tablist.description == '')) ?  tablist.description = 'N/A' : tablist.description;
              const regex = /(<([^>]+)>)/gi;
              const desc = description.replace(regex, "");

              var short_description = ((tablist.short_description == null)||(tablist.short_description == '')) ?  tablist.short_description = 'N/A' : tablist.short_description;
              var cat_name = ((tablist.cat_name == null)||(tablist.cat_name == '')) ?  tablist.cat_name = 'N/A' : tablist.cat_name;
              // var icon = ((tablist.image == null)||(tablist.image == '')) ?  tablist.image = 'N/A' : "<img src=" + {tabUrltablist.image} + "/>";
              var icon = "";
              if((tablist.image == null)||(tablist.image == '')){
                icon = "N/A";
              }else{
                var urll = tabUrl + '/' + tablist.image;
                icon = <img src={urll}/>;
              }
              return (
                <tr key={tablist.id}>
                  <td>{sno}</td>
                  <td>{cat_name}</td>
                  <td>{icon}</td>
                  <td>{desc}</td>
                  <td>{short_description}</td>
                  <td>{lang.Capitalize(status)}</td>                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/tabmanagement-view/'+tablist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/tabmanagement-edit/'+tablist.id}} className="" title="Edit">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, tablist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, tablist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}          
                     <Link onClick={this.delete.bind(this,tablist.id)}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return tablistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the tab?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="tabmanagement" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Tabs Management</h3>:<h3>{this.state.totalRecord} Tab Management</h3>}
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/tabmanagement-add'>Add</a>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Tab Name</th>
				        <th>Icon</th>
				        <th>Description</th>
				        <th>Short Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.tablist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>

  <div className="confirm-action-delete">
          <p className="confirm-action-delete-message"></p>
          
          
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.tabid)} className="btn">Yes</a>
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
          <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
        </div>
      
    </div>
    );
  }
}

export default List;
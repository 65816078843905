import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const cateid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      cateid: cateid,
      categorylistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"quizcategory/listdetail?id="+cateid).then(res => {
        if(res.data.status == 'success'){
          const formdata = res.data.categorylist;
          this.setState({categorylistview:res.data.categorylist});						
        }			
      }); 
    }

   
   componentDidMount() {
     document.title = PageTitle('Quiz Category View');
   }
   
  render() {   

    var parentcate_name = ((this.state.categorylistview.parentcate_name === null)||((this.state.categorylistview.parentcate_name === ''))) ?  this.state.categorylistview.parentcate_name = 'N/A' : this.state.categorylistview.parentcate_name;
    
    if(this.state.categorylistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="quizcategory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				          <a className="add" href='/quizcategory'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Quiz Category Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.categorylistview.cate_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Subcategory Of<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{parentcate_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>
                    </div>
                </div>               
              </div>  
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import MyEditor from './MyEditor';

const initialState = {
	quiz_question:'',
	quiz_answer:'',
	quiz_answer2:'',
	quiz_answer3:'',
	quiz_answer4:'',
	quiz_answer5:'',
	correct_answer:'',
	quiz_mode:'',
	quiz_answer_desc:'',
	selectedOption:{ value: 'active', label: 'Active'},	
	selectedvalue:'active',
	selectedCatevalue:'',
	selectedModeOption: "",
	selectedModevalue:'',
	selectedCateOption: [],
	selectedCatevalue:'',
	
};

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = { ...initialState }

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }

	onEditorChange( evt ) {
		var callfunc = this;
		setTimeout(function () {
			this.setState( {
				quiz_answer_desc: evt
			} );
		}.bind(this),1000);		
	}

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){			
			
			let selCate = [];
			let selCateValue = [];
			selOption.map(item => {
				selCateValue.push(item.value);
				selCate.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedCateOption:selCate})
			this.setState({  selectedCatevalue : selCateValue});

		}else if(r == 'mode'){			
			this.setState({selectedModeOption:selOption})
			this.setState({  selectedModevalue : selOption.value});
		}
		console.log(selOption.value, 'sdfg')
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => {
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate, mode = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedCatevalue !== '' && formdata.selectedCatevalue!== 0){
		cate = formdata.selectedCatevalue;
	}

	if(formdata.selectedModevalue === '' && formdata.selectedModevalue!== 0){
		mode =formdata.selectedModeOption.value;
	}else{
		mode = formdata.selectedModevalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		quiz_question : formdata.quiz_question,
		quiz_answer:formdata.quiz_answer,
		quiz_answer2:formdata.quiz_answer2,
		quiz_answer3:formdata.quiz_answer3,
		quiz_answer4:formdata.quiz_answer4,
		quiz_answer5:formdata.quiz_answer5,
		correct_answer:formdata.correct_answer,
		quiz_answer_desc:formdata.quiz_answer_desc,
		status: status,
		cate_id:cate,
		quiz_mode:mode
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"quiz/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/quiz'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {quiz_question,quiz_answer,quiz_answer2, selectedCateOption, quiz_answer_desc,selectedModeOption} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!quiz_question){
		formIsValid = false;
		$('.errorquiz_question').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorquiz_question').html('');
	}

	if(!quiz_answer){
		formIsValid = false;
		$('.errorquiz_answer').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorquiz_answer').html('');
	}

	if(!quiz_answer2){
		formIsValid = false;
		$('.errorquiz_answer2').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorquiz_answer2').html('');
	}
	
	if(!quiz_answer_desc){
		formIsValid = false;
		$('.errorquiz_answer_desc').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorquiz_answer_desc').html('');
	}

	if(selectedCateOption.length === 0){
		formIsValid = false;
		$('.errorcate').html('<span class="errorspan">Please select atleast one category</span>');
	}else{
		
		$('.errorcate').html('');
	}

	if(selectedModeOption.length === 0){
		formIsValid = false;
		$('.errormode').html('<span class="errorspan">Please select atleast one mode</span>');
	}else{
		
		$('.errormode').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const {selectedOption,selectedCateOption,selectedModeOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="quiz" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/quiz'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Question</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row quiz-row">
			<div className="form-left">

				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedCateOption?selectedCateOption:""}
                            options={lang.common.quizcategories_all_option} 
                            onChange={this.handleChange.bind(this, 'cate')}
							isSearchable={true}
  							isMulti
                            />
							<div className="errorcate"></div>
	           </div> 	

			   <div className="form-group">					
						<label>Mode<span class="required" style={{ color: "red" }} > * </span> :</label>
							<Select 
							value={selectedModeOption?selectedModeOption:""}
								options={lang.common.quiz_mode_option} 
								onChange={this.handleChange.bind(this, 'mode')}
								/>
								<div className="errormode"></div>
				</div>	
				
				
			</div>
                  
			<div className="form-right">
			
			
				<div className="form-group">
					<label>Question<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="quiz_question" onChange={this.handleInputChange} className="form-control" value={this.state.quiz_question}/>
					<div className="errorquiz_question"></div>
				</div>
				
			</div>	

			<div style={{width:'100%'}}>
			{/* <span style={{fontSize:'16px'}}>Note : Checked answer will be the correct one.</span>			 */}
				<div className="form-group">	
					<label>Answer Description<span class="required" style={{ color: "red" }} > * </span> :</label>								
					<MyEditor initialData={this.state.quiz_answer_desc} onEditorChange={this.onEditorChange}> </MyEditor>	
					{/* <textarea type="text" name="quiz_answer_desc" onChange={this.handleInputChange} className="form-control" value={this.state.quiz_answer_desc}  /> */}
						{/* <CKEditor
					data={this.state.quiz_answer_desc}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<div className="errorquiz_answer_desc"></div>
				</div>

				<div className="form-group">					
				<label>Option A<span class="required" style={{ color: "red" }} > * </span> :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='1'?'checked':''} value="1"/> Mark as correct option</span>
						<textarea type="text" name='quiz_answer' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer} />
						{/* <CKEditor
					data={this.state.quiz_answer}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<div className="errorquiz_answer"></div>
				</div>

				<div className="form-group">					
					<label>Option B<span class="required" style={{ color: "red" }} > * </span> :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked=	{this.state.correct_answer=='2'?'checked':''} value="2"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer2' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer2} />
					<div className="errorquiz_answer2"></div>
				</div>

				<div className="form-group">					
					<label>Option C :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='3'?'checked':''} value="3"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer3' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer3} />
				</div>

				<div className="form-group">					
					<label>Option D :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='4'?'checked':''} value="4"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer4' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer4} />
				</div>

				<div className="form-group">					
					<label>Option E :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='5'?'checked':''} value="5"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer5' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer5} />
				</div>
			</div>

			<div className="form-left">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			</div>
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import MyEditor from './MyEditor';



const initialState = {
		cat_name:'',
		description:'',
		short_description:'',
		module:'',
		button_text:'',
		image:'',
		image_name: '',
		imagename:'',
		image_preview: '',
		status:'',
		selectedOption:{ value: 'active', label: 'Active'},
		selectedvalue:'',
		selectedModuleOption:'',
		selectedModulevalue:'',
		tab_image:''
		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	fileObj = [];
	fileArray = [];
	imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
		this.handleInputChange = this.handleInputChange.bind(this);
       this.handleChange = this.handleChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.removeImagebyindex = this.removeImagebyindex.bind(this);
    }

	onChangeHandler(event){		
		let reader = new FileReader();
		const file = event.target.files[0];
		
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
		console.log(this.state, 'evvvve')
	}

	

	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')

	   var callfunc = this;
	   setTimeout(function () {
		this.setState( {
            description: evt
        },()=>{
			console.log('hloo');
		} );

		}.bind(this),1000);
	
   }

	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    // handleChange = selectedOption => {
    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'module'){			
			this.setState({selectedModuleOption:selOption})
			this.setState({  selectedModulevalue : selOption.value});
		}
 	};

	componentDidMount() {
		document.title = PageTitle('Add Tab');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }
		
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status, module = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedModulevalue === '' && formPayload.selectedModulevalue!== 0){
					module =formPayload.selectedModuleOption.value;
				}else{
					module = formPayload.selectedModulevalue;
				}

				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					cat_name     : formPayload.cat_name,
					description    : formPayload.description,
					short_description    : formPayload.short_description,
					image: formPayload.image,
					button_text: formPayload.button_text,
					module     : module,
					status       :  status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       

				var filesdata = this.state.tab_image;
				if(filesdata){
				for (var i in filesdata) {
					formData.append('multiple_images[]',filesdata[i])
				}
			  }
				axios.post(apiUrl+"tabmanagement/add",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							cat_name:'',
							short_description:'',
							module:''
						   });
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/tabmanagement');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {cat_name,description,short_description, module, selectedModulevalue} = this.state;

		let errors = 0;
		if (!cat_name) {			
			errors++;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			
			$('.errorcat_name').html('');
		}

		// if (selectedModulevalue == "") {			
		// 	errors++;
		// 	$('.errormodule').html('<span class="errorspan">Please select any one</span>');
		// }else if(description){
			
		// 	$('.errormodule').html('');
		// }

		if (!description) {			
			errors++;
			$('.errordesc').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			
			$('.errordesc').html('');
		}

		if (!short_description) {			
			errors++;
			$('.errorshort').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			
			$('.errorshort').html('');
		}

		// if (selectedModulevalue === "") {			
		// 	errors++;
		// 	$('.errormodule').html('<span class="errorspan">Please fill the field</span>');
		// }else if(module){
			
		// 	$('.errormodule').html('');
		// }
		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    componentWillReceiveProps(Props){
    }

	
	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			
			this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	removeImagebyindex = (indexvalue) => {
		   
			var list = [...this.imageArray];
			this.imageArray = [];
			this.fileArray = [];
			list.splice(indexvalue, 1);
		   
			for (let i = 0; i < list.length; i++) {
			  this.imageArray.push(list[i]);
			  this.fileArray.push(URL.createObjectURL(list[i]));
			}
		  
			  this.setState({ tab_image: this.imageArray })	
	  }
	
  render() {
  	
  	const {cat_name,short_description,module,selectedOption,selectedModuleOption} = this.state;

	  let imageslist = [];

	  let indexlength =  imageslist.length;
  
	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))

	  let loaderimg = '';
	  let preview = '';
	  var current = this;

	  const imagepre = this.state.image_preview;

	  if (this.state.image_preview!=='' && this.state.image_preview!== null && this.state.image_preview!== undefined ) {
	  	preview = <img className="img_class" src={imagepre} alt="" />;
	  }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tabmanagement" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a className="add" href='/tabmanagement'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Tab</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Tab Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>
				
				<div className="form-group">
					<label>Description<span class="required" style={{ color: "red" }} >*</span></label>
					<MyEditor id="description" initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
						{/* <textarea className="form-control" name="description" onChange={this.handleInputChange}  value={this.state.description} id="" rows="5"  autoComplete="off" /> */}
						<div className="errordesc"></div>
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
				
					<div className="errorloaderimage"></div>  
				</div> 
				<div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
			</div> 
			

							  
			</div>
			<div className="form-right">
				<div className="form-group">
					<label>Module:</label>
				    {/* <input readOnly type="text" name="module" onChange={this.handleInputChange} className="form-control" value={this.state.module} /> */}
					<Select
						value={selectedModuleOption?selectedModuleOption:""}
							options={lang.common.tab_module_option} 
							onChange={this.handleChange.bind(this, 'module')}
							/>
					<div className="errormodule"></div>
				</div>

				<div className="form-group">
					<label>Short Description<span class="required" style={{ color: "red" }} >*</span></label>
						<textarea className="form-control" name="short_description" onChange={this.handleInputChange}  value={this.state.short_description} id="" rows="5"  autoComplete="off" />
						<div className="errorshort"></div>
				</div>	

				<div className="form-group">
					<label>Button Text</label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
				</div>
					
                
				<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange}
								/>
					</div>
				</div>
			</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);
import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          tutorlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
         
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  tutorlist: res.data, totalRecord: res.data.records_count}, function() {
         this.tutorlist();
       });
   }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

activatetutor(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :this.state.selectid,
		      status: stat
        };
        axios.post(apiUrl+"tutor/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}


confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activatetutor();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}


delete(id){
  this.setState({tutorid : id})
  $('.confirm-action-delete-message').html('Are you sure! you want to delete?');	
  $('.confirm-action-delete').addClass('show');	
 }

 deleteConfirm =(confirmstatus,id)=>{
  if(confirmstatus == 'yes'){
    $('.confirm-action-delete').removeClass('show');
    var qs = require('qs');       
    var postObject = { 
      column: 'admin_id',         
      id: id,   
      activePage:this.state.activePage,  
      admin_id:localStorage.getItem("admin_id")
    };
    axios.post(apiUrl+"tutor/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){             
        this.listloadedData()
      }
    });  
  }else{
    $('.confirm-action-delete').removeClass('show');	
  }
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"tutor/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
  tutorlist() {

    var tutorlist = this.state.tutorlist;
    if (tutorlist != "undefined" && tutorlist != null) {
      if (tutorlist.status == "success") {
        if (Object.keys(tutorlist).length > 0) {
          const tutorlistDetails = tutorlist.tutorlist.map(
            (tutorlist, Index) => {
              let sno = Index+1;
              if(tutorlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
              
              var mobile = ((tutorlist.mobile == null)||(tutorlist.mobile == '')) ?  tutorlist.mobile = 'N/A' : tutorlist.mobile;
              var institute_name = ((tutorlist.institute_name == null)||(tutorlist.institute_name == '')) ?  tutorlist.institute_name = 'N/A' : tutorlist.institute_name;
              // var designation = ((tutorlist.designation == null)||(tutorlist.designation == '')) ?  tutorlist.designation = 'N/A' : tutorlist.designation;
              // var university_studied = ((tutorlist.university_studied == null)||(tutorlist.university_studied == '')) ?  tutorlist.university_studied = 'N/A' : tutorlist.university_studied;
              // var workplace = ((tutorlist.workplace == null)||(tutorlist.workplace == '')) ?  tutorlist.workplace = 'N/A' : tutorlist.workplace;
              var created_on = moment(tutorlist.created_on).format("MMM,DD YYYY hh:mm A");
              return (
                <tr key={tutorlist.id}>
                  <td>{sno}</td>
                  <td>{tutorlist.firstname}</td>
                  <td>{tutorlist.email}</td>
                  <td>{mobile}</td>
                  <td>{institute_name}</td>
                  <td>{tutorlist.username}</td>
                  <td>{tutorlist.orgpwd}</td>
                  <td>{created_on}</td> 
                  {/* {/* <td>{designation}</td> */}
                
                  {/* <td>{workplace}</td> */}
                  <td>{lang.Capitalize(status)}</td>                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/tutor-view/'+tutorlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/tutor-edit/'+tutorlist.id}} className="" title="Duplicate">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, tutorlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, tutorlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}          
                     <Link onClick={this.delete.bind(this,tutorlist.id)}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return tutorlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the tutor?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="tutor" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Tutors</h3>:<h3>{this.state.totalRecord} Tutor</h3>}
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/tutor-add'>Add </a> {/* &nbsp;|&nbsp;
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */ }
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
				        <th>Contact</th>
				        <th>Institute</th>
			          <th>Username</th>
			          <th>Password</th>
			          <th>Created On</th>
				        {/* <th>Designation</th>
				        <th>University</th> */}
				        {/* <th>Workplace</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.tutorlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>

    <div className="confirm-action-delete">
          <p className="confirm-action-delete-message"></p>
          
          
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.tutorid)} className="btn">Yes</a>
          <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
          <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
        </div>
      
    </div>
    );
  }
}

export default List;
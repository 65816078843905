import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import MyEditor from './MyEditor';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);
	    const quizId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
            quizId: quizId,
			quiz_id:'',
			quiz_question:'',
			quiz_answer:'',
			quiz_answer2:'',
			quiz_answer3:'',
			quiz_answer4:'',
			quiz_answer5:'',
			correct_answer:'',
			quiz_mode:'',
			quiz_answer_desc:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedCateOption: [],
			selectedModeOption: "",
			selectedvalue:'',
			selectedCatevalue:'',
			selectedModevalue:'',
		};
		// this.props.getCategoryDetailList(quizId);
		var qs = require('qs');
		var catid = this.state.quizId;

		axios.get(apiUrl+"quiz/listdetail?id="+catid).then(res => {
			this.setState({quiz_question:res.data.quizlist.quiz_question,
				quiz_answer:res.data.quizlist.quiz_answer,
				quiz_answer2:res.data.quizlist.quiz_answer2,
				quiz_answer3:res.data.quizlist.quiz_answer3,
				quiz_answer4:res.data.quizlist.quiz_answer4,
				quiz_answer5:res.data.quizlist.quiz_answer5,
				correct_answer:res.data.quizlist.correct_answer,
				quiz_id:res.data.quizlist.quiz_id,
				quiz_mode:res.data.quizlist.quiz_mode,
				quiz_answer_desc:res.data.quizlist.quiz_answer_desc
			});
			
			if(res.data.quizlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}

			if(res.data.quizlist.quiz_mode !== ''){
				this.setState({selectedModeOption:{value: res.data.quizlist.quiz_mode, label: res.data.quizlist.quiz_mode}});
			}

			if(res.data.quizcatelist){
				this.setState({selectedCateOption:res.data.quizcatelist});
			}

			// var postObject = {
			// 	id: res.data.quizlist.quiz_cate_id,			
			// };

			// axios.post(apiUrl+"quiz/catedetail",qs.stringify(postObject)).then(res => {
			// 	if(res.data.status == "success"){					
			// 		let catedetail = res.data.catedetail;
			// 		this.setState({selectedCateOption:{value: catedetail['id'], label: catedetail['cate_name']}});					
			// 	}
			// });
			
			
		}); 

		//axios.get(apiUrl+'quiz/getspecialistnamelist?admin_id='+localStorage.getItem("admin_id"))
		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }

	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')

	   var callfunc = this;
	   setTimeout(function () {
		this.setState( {
            quiz_answer_desc: evt
        } );
		}.bind(this),1000);
	
   }

	// onEditorChange( evt ) {
    // 	// console.log(evt.editor.getData(), 'CKE')
    // 	var callfunc = this;
    // 	setTimeout(function () {
	// 	callfunc.setState( {
    //         quiz_answer_desc: evt.editor.getData()
    //     } );
	// 	}, 1000);
     
    // }
    // handleChange = selectedOption => {

    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){		
			
			let selCate = [];
			let selCateValue = [];
			selOption.map(item => {
				selCateValue.push(item.value);
				selCate.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedCateOption:selCate})
			this.setState({  selectedCatevalue : selCateValue});
			console.log(selCate, 'selCate');
			console.log(selCateValue, 'selectedCatevalue');
		}else if(r == 'mode'){			
			this.setState({selectedModeOption:selOption})
			this.setState({  selectedModevalue : selOption.value});
		}
 	};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}
	
	handleChangeSpecialist = selectedSpecialist => {

		this.setState({ selectedSpecialist});
		this.setState({ specialistvalue : selectedSpecialist.value });
 }


	componentDidMount() {
		document.title = PageTitle('quiz Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status, mode = '';
				var cate = [];
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedCatevalue !== '' && formPayload.selectedCatevalue!== 0){
					cate = formPayload.selectedCatevalue;
				}

				if(formPayload.selectedModevalue === '' && formPayload.selectedModevalue!== 0){
					mode =formPayload.selectedModeOption.value;
				}else{
					mode = formPayload.selectedModevalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					quiz_id: formPayload.quiz_id,
					quiz_question: formPayload.quiz_question,
					quiz_answer:formPayload.quiz_answer,
					quiz_answer2:formPayload.quiz_answer2,
					quiz_answer3:formPayload.quiz_answer3,
					quiz_answer4:formPayload.quiz_answer4,
					quiz_answer5:formPayload.quiz_answer5,
					correct_answer:formPayload.correct_answer,
					quiz_answer_desc:formPayload.quiz_answer_desc,
					status:  status,
					id: formPayload.quizId,
					cate_id:cate,
					quiz_mode:mode
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"quiz/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									// $('.success_message').html(this.props.navigate('/quiz'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
						this.setState({Loading:false});
					}
				});
			}
	}

	validateForm() {
		const {quiz_question,quiz_answer,quiz_answer2,selectedCateOption,quiz_answer_desc,selectedModeOption} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		  if(!quiz_question){
			formIsValid = false;
			$('.errorquiz_question').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorquiz_question').html('');
		}

		if(!quiz_answer){
			formIsValid = false;
			$('.errorquiz_answer').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorquiz_answer').html('');
		}

		if(!quiz_answer2){
			formIsValid = false;
			$('.errorquiz_answer2').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorquiz_answer2').html('');
		}

		if(!quiz_answer_desc){
			formIsValid = false;
			$('.errorquiz_answer_desc').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorquiz_answer_desc').html('');
		}

		if(selectedCateOption.length === 0){
			formIsValid = false;
			$('.errorcate').html('<span class="errorspan">Please select atleast one category</span>');
		}else{
			
			$('.errorcate').html('');
		}

		if(selectedModeOption.length === 0){
			formIsValid = false;
			$('.errormode').html('<span class="errorspan">Please select atleast one mode</span>');
		}else{
			
			$('.errormode').html('');
		}
			
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
  render() {


	

  	  const {selectedOption,selectedCateOption,selectedModeOption} = this.state;	


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="quiz" />  
      <div className="content">	
		<div className="content-wrapper">
		<div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/quiz'>Back</a> 
			</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row quiz-row">
			<div className="form-left">
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   className="multiSelect"
						   value={selectedCateOption?selectedCateOption:""}
						//    value={[{value:1, label:'Task Area'},{value:2, label:'Test'}]}
                            options={lang.common.quizcategories_all_option} 
                            onChange={this.handleChange.bind(this, 'cate')}
							isSearchable={true}
  							isMulti
                            />
							<div className="errorcate"></div>
							
	           </div> 

			   <div className="form-group">
					<label> Question ID : </label>
					<input type="text" disabled name="quiz_id" className="form-control" value={this.state.quiz_id} />
				</div>
			</div>

			   <div className="form-right">
				
					<div className="form-group">
						<label> Question<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="quiz_question" onChange={this.handleInputChange} className="form-control" value={this.state.quiz_question} />
						<div className="errorquiz_question"></div>
					</div>
					
					<div className="form-group">					
						<label>Mode<span class="required" style={{ color: "red" }} > * </span> :</label>
							<Select 
							value={selectedModeOption?selectedModeOption:""}
								options={lang.common.quiz_mode_option} 
								onChange={this.handleChange.bind(this, 'mode')}
								/>
								<div className="errormode"></div>
					</div>
				

				</div>	

				<div className="fullwidth" style={{width:'100%'}}>		
				{/* <span style={{fontSize:'16px'}}>Note : Checked answer will be the correct one.</span>			 */}
				<div className="form-group">	
					<label>Answer Description<span class="required" style={{ color: "red" }} > * </span> :</label>			
					<MyEditor initialData={this.state.quiz_answer_desc} onEditorChange={this.onEditorChange}> </MyEditor>					
					{/* <textarea type="text" name="quiz_answer_desc" onChange={this.handleInputChange} className="form-control" value={this.state.quiz_answer_desc}  /> */}
						{/* <CKEditor
					initData={this.state.quiz_answer_desc}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<div className="errorquiz_answer_desc"></div>
				</div>

				<div className="form-group">					
					<label>Option A<span class="required" style={{ color: "red" }} > * </span> :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='1'?'checked':''} value="1"/> Mark as correct option</span>
					<textarea type="text" name="quiz_answer" onChange={this.handleInputChange} className="form-control" value={this.state.quiz_answer}  />
					<div className="errorquiz_answer"></div>
				</div>

				<div className="form-group">					
					<label>Option B<span class="required" style={{ color: "red" }} > * </span> :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='2'?'checked':''} value="2"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer2' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer2} />
					<div className="errorquiz_answer2"></div>
				</div>
				<div className="form-group">					
					<label>Option C :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='3'?'checked':''} value="3"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer3' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer3} />
				</div>
				<div className="form-group">					
					<label>Option D :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='4'?'checked':''} value="4"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer4' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer4} />
				</div>
				<div className="form-group">					
					<label>Option E :</label> <span><input onChange={this.handleInputChange} type="radio" name="correct_answer" checked={this.state.correct_answer=='5'?'checked':''} value="5"/> Mark as correct option</span>
					<textarea type="text" name='quiz_answer5' onChange={this.handleInputChange} className='form-control' value={this.state.quiz_answer5} />
				</div>
				</div>
			
				<div className="form-left">
				<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange.bind(this, 'status')}
								/>
				</div> 
				</div> 
			
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));
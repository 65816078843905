import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWtablist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      id: id,
      tablistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"tabmanagement/listdetail?id="+id).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.tablist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({tablistview:res.data.tablist});
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('tabmanagement View');
   }

   componentDidUpdate(prevProps,prevState){

   }

   componentWillReceiveProps(Props){
      
   }
   
  render() {
    

    // var module = ((this.state.tablistview.module == null)||(this.state.tablistview.module == '')) ?  this.state.tablistview.module = 'N/A' : this.state.tablistview.module;
    var short_description = ((this.state.tablistview.short_description === null)||(this.state.tablistview.short_description === '')) ?  this.state.tablistview.short_description = 'N/A' : this.state.tablistview.short_description;
    var cat_name = ((this.state.tablistview.cat_name === null)||(this.state.tablistview.cat_name === '')) ?  this.state.tablistview.cat_name = 'N/A' : this.state.tablistview.cat_name;

    if(this.state.tablistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="tabmanagement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
			          	<a className="add" href='/tabmanagement'>Back</a> 
		        	</div>
                <div className="title">
                  <h3>Tab Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Tab Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{cat_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Short Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{short_description}</p>
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Module<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{module}</p>
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                        



                    </div>
                </div>
                 
                  
               
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);

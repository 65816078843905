import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

const initialState = {
		exam_name:'',			
		type:'',			
		duration:'',	
		no_of_ques:'',	
		model_exam:false,		
		status:'',
		scheduled_on:new Date(),
		selectedOption:{ value: 'active', label: 'Active'},
		selectedvalue:'',
		selectedCateOption: [],
		selectedCatevalue:'',
		selectedTutorOption: "",
		selectedTutorvalue:'',
		selectedStudentOption: [],
		selectedStudentvalue:'',
		selectedClassOption: [],
		selectedClassvalue:'',
		selectedTutorInstitute: ""

		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
       this.handleChange = this.handleChange.bind(this);

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }

	  if(name === 'model_exam'){
		this.setState({model_exam: !this.state.model_exam});
	  }
    }
    handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){		
			
			let selCate = [];
			let selCateValue = [];
			selOption.map(item => {
				selCateValue.push(item.value);
				selCate.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedCateOption:selCate})
			this.setState({  selectedCatevalue : selCateValue});
			console.log(selCate, 'selCate');
			console.log(selCateValue, 'selectedCatevalue');
		}else if(r == 'tutor'){		
			this.setState({selectedClassOption:[]})
			this.setState({selectedClassvalue:""});	
			this.setState({selectedTutorOption:selOption})
			this.setState({  selectedTutorvalue : selOption.value});

			var qs = require('qs');
			var postObject = {
				id: selOption.value			
			};

			axios.post(apiUrl+"tutor/tutordetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let tutordetail = res.data.tutordetail;
					this.setState({selectedTutorInstitute: tutordetail.institute});					
				}
			});
		}else if(r == 'student'){			
			let selStudent = [];
			let selStudentValue = [];
			selOption.map(item => {
				selStudentValue.push(item.value);
				selStudent.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedStudentOption:selStudent})
			this.setState({  selectedStudentvalue : selStudentValue});
		}else if(r == 'class'){			
			let selClass = [];
			let selClassValue = [];
			selOption.map(item => {
				selClassValue.push(item.value);
				selClass.push({ label: item.label, value: item.value });
			});
			
			this.setState({selectedClassOption:selClass})
			this.setState({  selectedClassvalue : selClassValue});
		}
 	};

	classlist = () => {
		var qs = require('qs');
		var postobject = {
			id: this.state.selectedTutorInstitute
		};
		
		let classlists = [];
		axios.post(apiUrl+"classes/classeslist",qs.stringify(postobject)).then(res => {        
			if(res.data.status === "success"){
				let i=0;
				let classlist = res.data.classlist;           
				
				for(i; i<classlist.length; i++){
					
					let sname = classlist[i]['name'];
					classlists[i] = { value: classlist[i]['id'], label: sname };
				}
				
			}       
		});
		return classlists;
	}

	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add Session');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		

    }

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
				var status, tutor = '';
				var cate, student, classes = [];
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedCatevalue !== '' && formPayload.selectedCatevalue!== 0){
					cate = formPayload.selectedCatevalue;
				}

				if(formPayload.selectedTutorvalue === '' && formPayload.selectedTutorvalue!== 0){
					tutor =formPayload.selectedTutorOption.value;
				}else{
					tutor = formPayload.selectedTutorvalue;
				}

				if(formPayload.selectedStudentvalue !== '' && formPayload.selectedStudentvalue!== 0){
					student = formPayload.selectedStudentvalue;
				}

				if(formPayload.selectedClassvalue !== '' && formPayload.selectedClassvalue!== 0){
					classes = formPayload.selectedClassvalue;
				}

				var model_exam = 0;
				if(formPayload.model_exam == true){
					model_exam = 1;
				}

				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					exam_name     : formPayload.exam_name,					
					type     	: formPayload.type,	
					duration     	: formPayload.duration,	
					no_of_ques     	: formPayload.no_of_ques,	
					model_exam		: model_exam,
					scheduled_on     	: formPayload.scheduled_on,	
					cate_id		:cate,				
					student		:student,	
					class		:classes,				
					tutor		:tutor,				
					status       :  status,
				};
				axios.post(apiUrl+"sessionmanagement/add",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.sessionlist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/sessionmanagement');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {exam_name,type,selectedCateOption,selectedStudentOption,selectedClassOption,selectedTutorOption,duration,scheduled_on,no_of_ques} = this.state;
		let errors = 0;
		const nameRegex = /^[A-Za-z\s]+$/;

		if (!exam_name) {
			
			errors++;
			$('.errorexam_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(exam_name){
			
			$('.errorexam_name').html('');
		}

		if (!type) {			
			errors++;
			$('.errortype').html('<span class="errorspan">Please fill the field</span>');
		}else if(type){			
			$('.errortype').html('');
		}

		if (!duration) {			
			errors++;
			$('.errorduration').html('<span class="errorspan">Please fill the field</span>');
		}else if(duration){
			
			$('.errorduration').html('');
		}

		if (!no_of_ques) {			
			errors++;
			$('.errorno_of_ques').html('<span class="errorspan">Please fill the field</span>');
		}else if(no_of_ques){
			
			$('.errorno_of_ques').html('');
		}

		if (!scheduled_on) {			
			errors++;
			$('.errorscheduled_on').html('<span class="errorspan">Please fill the field</span>');
		}else if(scheduled_on){
			
			$('.errorscheduled_on').html('');
		}

		if (!selectedTutorOption) {
			
			errors++;
			$('.errortutor').html('<span class="errorspan">Please select any tutor</span>');
		}else if(selectedTutorOption){
			
			$('.errortutor').html('');
		}
		
		if(selectedCateOption.length === 0){
			// formIsValid = false;
			$('.errorcate').html('<span class="errorspan">Please select atleast one category</span>');
		}else{
			
			$('.errorcate').html('');
		}

		if(selectedStudentOption.length === 0){
			// formIsValid = false;
			$('.errorstudent').html('<span class="errorspan">Please select atleast one student</span>');
		}else{
			
			$('.errorstudent').html('');
		}

		if(selectedClassOption.length === 0){
			// formIsValid = false;
			$('.errorclass').html('<span class="errorspan">Please select atleast one class</span>');
		}else{
			
			$('.errorclass').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

	
  render() {
  	
	const {selectedOption,selectedTypeOption,selectedCateOption,selectedTutorOption,selectedClassOption,selectedStudentOption} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="sessionmanagement" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a className="add" href='/sessionmanagement'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Session</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Session Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="exam_name" onChange={this.handleInputChange} className="form-control" value={this.state.exam_name} />
					<div className="errorexam_name"></div>
				</div>
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedCateOption?selectedCateOption:""}
                            options={lang.common.quizcategories_all_option} 
                            onChange={this.handleChange.bind(this, 'cate')}
							isSearchable={true}
  							isMulti
                            />
							<div className='errorcate'></div>
	           </div> 
			   <div className="form-group">				 				
					<label>Tutor<span class="required" style={{ color: "red" }} >*</span></label>
					<Select 
					value={selectedTutorOption?selectedTutorOption:""}
					options={lang.common.tutors_option} 
					onChange={this.handleChange.bind(this, 'tutor')}
					/>
					<div className="errortutor"></div>
	           </div>

			   <div className="form-group">
					<label>Scheduled On<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="datetime-local" name="scheduled_on" onChange={this.handleInputChange} className="form-control" value={this.state.scheduled_on} />
					<div className="errorscheduled_on"></div>
				</div>

				<div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox"  name="model_exam" checked={this.state.model_exam}/>
	                <span>Test session for guest users</span>
            	</div>

				  
				</div>
				<div className="form-right">
					<div className="form-group">					
						<label>Type<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="type" onChange={this.handleInputChange} className="form-control" value={this.state.type} />
						<div className="errortype"></div>
					</div>
					<div className="form-group">					
						<label>Duration (in mins)<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="number" name="duration" onChange={this.handleInputChange} className="form-control" value={this.state.duration} />
						<div className="errorduration"></div>
					</div>

					{/* <div className="form-group">					
						<label>Student<span class="required" style={{ color: "red" }} > * </span> :</label>
							<Select 
							value={selectedStudentOption?selectedStudentOption:""}
								options={lang.common.students_option} 
								onChange={this.handleChange.bind(this, 'student')}
								isSearchable={true}
								isMulti
								/>
								<div className='errorstudent'></div>
					</div>  */}

					{/* {selectedTutorOption != "" && */}
					<div className="form-group">					
						<label>Class<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedClassOption?selectedClassOption:""}
								options={this.classlist()} 
								onChange={this.handleChange.bind(this, 'class')}
								isSearchable={true}
								isMulti
								/>
								<div className='errorclass'></div>
					</div>
  					{/* } */}

					<div className="form-group">					
						<label>No. of Questions<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="number" min="1" name="no_of_ques" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_ques} />
						<div className="errorno_of_ques"></div>
					</div>

					<div className="default-font">
					<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange.bind(this, 'status')}
								/>
					</div>
				</div>
				</div>
			</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);
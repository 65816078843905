import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import Geocode from "react-geocode";

const initialState = {
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			address:'',
			// graduation:'',
			// designation:'',
			// university_studied:'',
			// workplace:'',
			status:'',
			password: '',
            confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			selectedInsOption:'',
			selectedInsvalue:'',

		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
       this.handleChange = this.handleChange.bind(this);
	   Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }

	  const google = window.google;
		const callfunc = this;

		if(name == 'address'){

			var input = document.getElementById('address');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
		   // document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress = place.formatted_address;
			
			callfunc.setState({address: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			});		
	     }
    }

	getgoecoder(input, name){

		$('#address').val('');
			
		if(name == 'address'){ 
			Geocode.fromAddress(input).then(
			  response => {
				console.log(response,'response')
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					address: response.results[0].formatted_address
				});	
				
				/* document.getElementById('address').value = response.results[0].formatted_address;
				$('#address').val(response.results[0].formatted_address) */;				  
			
			  },
			  error => {
				console.error(error);
			  }
			);

			
		}

	}
    // handleChange = selectedOption => {
    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };
	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'inst'){			
			this.setState({selectedInsOption:selOption})
			this.setState({  selectedInsvalue : selOption.value});
		}
 	};
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}
	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add Tutor');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"tutor/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
		
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		

		

    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    } 

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status,institute = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedInsvalue === '' && formPayload.selectedInsvalue!== 0){
					institute =formPayload.selectedInsOption.value;
					}else{
					institute = formPayload.selectedInsvalue;
					}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id       : formPayload.selectedDomainvalue,
					username     : formPayload.username,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					address     : formPayload.address,
					institute	 : institute,
					// graduation     : formPayload.graduation,
					// designation     : formPayload.designation,
					// university_studied     : formPayload.university_studied,
					// workplace     : formPayload.workplace,
					password     : formPayload.password,
					confirm_password:formPayload.confirm_password, 
					status       :  status,
				};
				console.log('domain_id',postObject);
				axios.post(apiUrl+"tutor/add",qs.stringify(postObject)).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							username:'',			
							firstname:'',
							lastname:'',
							email:'',
							phoneno:'',
							address:'',
							// graduation:'',
							// designation:'',
							// university_studied:'',
							// workplace:'',
							password: '',
							confirm_password:''
						   });
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/tutor');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, password, confirm_password,selectedDomainvalue,maxCharacterLimit,address,selectedInsOption/*,graduation,designation,university_studied,workplace*/} = this.state;
		let errors = 0;
		const nameRegex = /^[A-Za-z\s]+$/;

		if (!username) {
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			if (username.length <= maxCharacterLimit) {
				$('.errorusername').html('');
	
			  }else{
				$('.errorusername').html('Username should be less than 20 characters');
	
			  }
			
		}

		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid first name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}

		 if(lastname){
			if (!nameRegex.test(lastname)) {
				errors++;
			$('.errorlastname').html('<span class="errorspan">Please enter the valid last name</span>');
			}else{
				$('.errorlastname').html('');
			}
			
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

				if (!address) {
					errors++;
					$('.erroraddress').html('<span class="errorspan">Please fill the field</span>');
				}else if(address){					
					$('.erroraddress').html('');								
				}
				/*
				if (!university_studied) {
					errors++;
					$('.erroruniversity').html('<span class="errorspan">Please fill the field</span>');
				}else if(university_studied){					
					$('.erroruniversity').html('');								
				}
	
				if (!graduation) {
					errors++;
					$('.errorgraduation').html('<span class="errorspan">Please fill the field</span>');
				}else if(graduation){					
					$('.errorgraduation').html('');								
				}

				if (!designation) {
					errors++;
					$('.errordesignation').html('<span class="errorspan">Please fill the field</span>');
				}else if(designation){					
					$('.errordesignation').html('');								
				}

				if (!workplace) {
					errors++;
					$('.errorworkplace').html('<span class="errorspan">Please fill the field</span>');
				}else if(workplace){					
					$('.errorworkplace').html('');								
				}*/

		if (!password) {
			errors++;
			$('.errorpassword').html('<span class="errorspan">Please fill the field</span>');
		}else if(password){
		 if (password.match(/[a-z]/g) && password.match( 
                    /[A-Z]/g) && password.match( 
                    /[0-9]/g) && password.match( 
                    /[^a-zA-Z\d]/g) && password.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	errors++;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		/*
		if (!confirm_password) {
			errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Please fill the confirm password</span>');
		}else if(confirm_password){
		 if (confirm_password.match(/[a-z]/g) && confirm_password.match( 
                    /[A-Z]/g) && confirm_password.match( 
                    /[0-9]/g) && confirm_password.match( 
                    /[^a-zA-Z\d]/g) && confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		if(password !== confirm_password){
          	errors++;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }*/

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    componentWillReceiveProps(Props){

    		if(Props.useradd !== this.props.useradd){
    		if(Object.keys(Props.useradd).length > 0){
				this.setState({Loading:false});

				if(Props.useradd[0].status === "success"){

				 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
				scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/tutor'));
						}
						.bind(this),
						3000
						);
					this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',password:'',confirm_password:''})
					}else if(Props.useradd[0].status === "erroremail"){

					 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.erroremail').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorphone"){

					 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorphoneno').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorusername"){

					 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorusername').html('');
							}
							.bind(this),
							3000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
	
  render() {
  	
  	const {selectedOption,selectedDomainOption,selectedInsOption} = this.state;

console.log(selectedDomainOption,"selectedDomainOption")
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tutor" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a className="add" href='/tutor'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Tutor</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				{/* <div className="form-group">
					<label>Graduation<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="graduation" onChange={this.handleInputChange} className="form-control" value={this.state.graduation} autoComplete="off" />
					<div className="errorgraduation"></div>
				</div>
				
				<div className="form-group">
					<label>University Studied<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="university_studied" onChange={this.handleInputChange} className="form-control" value={this.state.university_studied} autoComplete="off" />
					<div className="erroruniversity"></div>
				</div> */}
			   	 
				  
			   <div className="form-group eye-pad">
                    <label>Password<span class="required" style={{ color: "red" }} >*</span></label>
                    <input type={this.state.new_pass_type}  name="password" placeholder="Enter new password" className="form-control" value={this.state.password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>

			   

				  <div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 


				

				  
				</div>
			<div className="form-right">
			<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				<div className="form-group">
					<label>Address<span class="required" style={{ color: "red" }} >*</span></label>						
					<input type="text" className="form-control input-focus" placeholder="" name="address" id="address" value={this.state.address} onChange={this.handleInputChange} autoComplete="off" />
					<div className="erroraddress"></div>
				</div>
				<div className="form-group">					
					<label>Institute</label>
						<Select 
						value={selectedInsOption?selectedInsOption:""}
							options={lang.common.institute_option} 
							onChange={this.handleChange.bind(this, 'inst')}
							/>
				</div>

				{/* <div className="form-group">
					<label>Designation<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="designation" onChange={this.handleInputChange} className="form-control" value={this.state.designation} autoComplete="off" />
					<div className="errordesignation"></div>
				</div>

				<div className="form-group">
					<label>Workplace<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="workplace" onChange={this.handleInputChange} className="form-control" value={this.state.workplace} autoComplete="off" />
					<div className="errorworkplace"></div>
				</div> */}

				
				 {/* <div className="form-group">					
					<label>Assign Domain : </label>
					<select  onChange={this.handleClickSelecet}  value={domainlist.domain_name}>                    
        {lang.common.domainlist.map((domainlist) => <option key={domainlist.value} data-key={domainlist.value} value={domainlist.value} >{domainlist.label}</option>)}
            </select>
						
					<div className="errorassign_domain"></div>
	</div>  */}
	                    
 {/* <div className="form-group eye-pad">
                    <label>Confirm Password<span class="required" style={{ color: "red" }} >*</span></label>
                    <input type={this.state.confrim_pass_type} name="confirm_password" id="confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
					
				  </div>
				  <div className="errorchangepaswd"></div> */}

				  <div className="default-font">
					<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange}
								/>
					</div>
					</div>
					
                
				</div>
			</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);
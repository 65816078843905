import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import { CheckAuth, PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import Geocode from "react-geocode";

const initialState = {
	username:'',
	title:'',
	email:'',
	phoneno:'',
	address:'',
	password:'',
	changepassword:'',
	new_pass_type: 'password',
	pass_show_hide_icon: 'shown',
	selectedOption:{ value: 'active', label: 'Active'},
	selectedvalue:'active',
	selectedOption1:'',
	selectedvalue1:''			
};

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
	
class Add extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
	}

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}

		if(r == 'ins_cate'){
			this.setState({selectedOption1:selOption})
			this.setState({  selectedvalue1 : selOption.value});
		}
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		const google = window.google;
		const callfunc = this;

		if(name == 'address'){

			var input = document.getElementById('address');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
		   // document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress = place.formatted_address;
			
			callfunc.setState({address: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			});		
	     }
	}

	getgoecoder(input, name){

		$('#address').val('');
			
		if(name == 'address'){ 
			Geocode.fromAddress(input).then(
			  response => {
				console.log(response,'response')
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					address: response.results[0].formatted_address
				});	
					  
			
			  },
			  error => {
				console.error(error);
			  }
			);

			
		}

	}
 

	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}

  
  handleSubmit = () => { 
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status,subcate_of = '';


	if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
	}else{
		status = formdata.selectedvalue;
	}

	if(formdata.selectedvalue1 === ''){
		subcate_of =formdata.selectedOption1.value;
	}else{
		subcate_of = formdata.selectedvalue1;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		username: formdata.username,
		title : formdata.title,
		email        : formdata.email,
		phoneno      : formdata.phoneno,
		address      : formdata.address,
		changepassword:formdata.changepassword, 
		status: status,
		subcate_of: subcate_of
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"institute/add",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/institute'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

	validateForm() {
		const {username, title, email, address, phoneno, changepassword} = this.state;

		let errors = 0;

		if(!username){
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else{			
			$('.errorusername').html('');
		}

		if(!title){
			errors++;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else{			
			$('.errortitle').html('');
		}

		if (!email) {
			errors++;			
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
				$('.erroremail').html('');
			}else{
				errors++;
				
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
		}

		if (!phoneno) {
			errors++;			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		}else if(phoneno){	
			var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
			if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
			}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
			}else{
				errors++;		
				$('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
			}
		}

		if (!address) {
			errors++;
			$('.erroraddress').html('<span class="errorspan">Please fill the field</span>');
		}else if(address){					
			$('.erroraddress').html('');								
		}

		// if(changepassword !=''){
		// 	if (changepassword.match(/[a-z]/g) && changepassword.match( 
		// 			/[A-Z]/g) && changepassword.match( 
		// 			/[0-9]/g) && changepassword.match( 
		// 			/[^a-zA-Z\d]/g) && changepassword.length >= 8){
		// 				$('.errorpassword').html('');
		// 	}else{
		// 		errors++;
		// 		$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		// 	}
		// }
			
		if(errors>0) {
			setTimeout(function () {
			scrollToTopValidate();
			}, 100);
			return false;
		}else {
			return true;
		}
	}



  render() {
	const {selectedOption, selectedOption1} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="institute" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="add" href='/institute'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Institute</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Institute Name<span class="required" style={{ color: "red" }} >* </span></label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortitle"></div>
				</div>	

				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>

				{/* <div className="form-group eye-pad">
                    <label>Password</label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
					<div className="errorpassword"></div>
                 </div>		 */}

				<div className="form-group">
					<label>Address<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" className="form-control input-focus" placeholder="" name="address" id="address" value={this.state.address} onChange={this.handleInputChange} autoComplete="off" />
					<div className="erroraddress"></div>
				</div>

				 
			</div>
                  
			<div className="form-right">

				<div className="form-group">
					<label>Username<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} />
					<div className="errorusername"></div>
				</div>

				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>

				

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           	</div> 	

				
				
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);
import React, { Component } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, mediaUrl,productURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import parse from 'html-react-parser';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const productid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;

    this.state = {      
      productid: productid,
      productlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :productid
    };

    axios.post(apiUrl+"product/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
          this.setState({ productlistdata: res.data.productlistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('product View');
   }
  render() {
    const navigate = this.props.navigate;
  if(this.state.productlistdata!==undefined){
    var productlistdata = this.state.productlistdata;
  }
  if((productlistdata.description!='')&&(productlistdata.description!=null)){
    var product_detail = productlistdata.description;
  }else{
   var product_detail = "";
  }

  
  let loaderimg = '';
  let preview = '';

 
  
  const imagepre = `${productURL}/${productlistdata.image}`;
		console.log(productURL,'image')
  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
    const loaderimg = `${productURL}/${productlistdata.image}`;

    preview = <img className="img_class" src={loaderimg} alt="" />;
    console.log(preview,'preview')
  }else{
    preview = <img className="img_class" src={imagepre} alt="" />;
    console.log(preview,'preview')
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Product" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Product View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Author<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.author}</p>
                        </div>
                      </div>
                   
                     
                     
                      
                   
                      {productlistdata.product_type!=1&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Price<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${productlistdata.price}</p>
                        </div>
                      </div>}

                      {productlistdata.product_type!=1&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Sale Price<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${productlistdata.saleprice}</p>
                        </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Quantity<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.quantity}</p>
                        </div>
                      </div> 

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>product Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{parse(product_detail)}</p>
                        </div>
                      </div>
                      {productlistdata.rating&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Rating<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.rating}</p>
                        </div>
                      </div>}
                     {productlistdata.review &&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Review<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.review} Reviews</p>
                        </div>
                      </div>}

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>product Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(productlistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Image<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {preview}
                        </div>
                      </div>
                    

                      </div>
                      </div>
                
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));